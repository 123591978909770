const JAVA_QUESTIONS = [
  // Core Java - Basics
  { id: 1, question: "What is Java and its main features?", type: "normal" },
  { id: 2, question: "Explain the differences between JDK, JRE, and JVM.", type: "normal" },
  { id: 3, question: "Why is Java platform-independent?", type: "normal" },
  { id: 4, question: "What is the difference between a class and an object?", type: "normal" },
  { id: 5, question: "What is the purpose of the final keyword in Java?", type: "normal" },
  { id: 6, question: "What are access specifiers? Explain with examples.", type: "normal" },
  { id: 7, question: "What is the difference between == and equals() in Java?", type: "normal" },
  { id: 8, question: "Explain method overloading and overriding with examples.", type: "normal" },
  { id: 9, question: "What is the difference between an abstract class and an interface?", type: "normal" },
  { id: 10, question: "Can a constructor be overridden in Java?", type: "normal" },
  { id: 11, question: "What is a static method? Can it be overridden?", type: "normal" },
  { id: 12, question: "Explain the this and super keywords in Java.", type: "normal" },
  { id: 13, question: "What are checked and unchecked exceptions in Java?", type: "normal" },
  { id: 14, question: "What is the purpose of the finally block in exception handling?", type: "normal" },
  { id: 15, question: "How does garbage collection work in Java?", type: "normal" },
  { id: 16, question: "Explain the difference between Array and ArrayList.", type: "normal" },
  { id: 17, question: "What is the difference between String, StringBuilder, and StringBuffer?", type: "normal" },
  { id: 18, question: "What are enum types in Java? Provide an example.", type: "normal" },
  { id: 19, question: "How does the hashCode() method work?", type: "normal" },
  { id: 20, question: "What is the purpose of the transient keyword?", type: "normal" },

  // Object-Oriented Programming (OOPs)
  { id: 21, question: "What are the main principles of OOP?", type: "normal" },
  { id: 22, question: "Explain encapsulation with examples.", type: "normal" },
  { id: 23, question: "How does inheritance work in Java? Provide examples.", type: "normal" },
  { id: 24, question: "What is polymorphism? Explain with examples.", type: "normal" },
  { id: 25, question: "What is the difference between compile-time and runtime polymorphism?", type: "normal" },
  { id: 26, question: "What is abstraction? How is it implemented in Java?", type: "normal" },
  { id: 27, question: "What is multiple inheritance? Does Java support it?", type: "normal" },
  { id: 28, question: "What is an inner class? Explain its types.", type: "normal" },
  { id: 29, question: "What is the diamond problem in Java? How is it resolved?", type: "normal" },
  { id: 30, question: "What are the benefits of using interfaces in Java?", type: "normal" },
  { id: 31, question: "Can you instantiate an abstract class? Why or why not?", type: "normal" },
  { id: 32, question: "Explain the role of constructors in inheritance.", type: "normal" },
  { id: 33, question: "What is an association, aggregation, and composition in OOP?", type: "normal" },
  { id: 34, question: "How is encapsulation achieved in Java?", type: "normal" },
  { id: 35, question: "What are virtual methods?", type: "normal" },

  // Java 8 Features
  { id: 36, question: "What are the new features introduced in Java 8?", type: "normal" },
  { id: 37, question: "Explain the concept of lambda expressions.", type: "normal" },
  { id: 38, question: "What is a functional interface in Java?", type: "normal" },
  { id: 39, question: "What is the Stream API? Provide examples of its usage.", type: "normal" },
  { id: 40, question: "What is the purpose of default and static methods in interfaces?", type: "normal" },
  { id: 41, question: "What is the Optional class in Java 8?", type: "normal" },
  { id: 42, question: "Explain the usage of forEach and map in Streams.", type: "normal" },
  { id: 43, question: "What are method references? Provide examples.", type: "normal" },
  { id: 44, question: "What is the difference between parallel streams and sequential streams?", type: "normal" },
  { id: 45, question: "How can you use collectors to group data in the Stream API?", type: "normal" },

  // Advanced Topics
  { id: 46, question: "What is multithreading? How is it implemented in Java?", type: "normal" },
  { id: 47, question: "Explain the concept of synchronization in Java.", type: "normal" },
  { id: 48, question: "What is the Executor framework?", type: "normal" },
  { id: 49, question: "What is the difference between synchronized and concurrent collections?", type: "normal" },
  { id: 50, question: "Explain the concept of a deadlock in multithreading.", type: "normal" },
  { id: 51, question: "What is the difference between Callable and Runnable?", type: "normal" },
  { id: 52, question: "What are design patterns in Java? Name a few.", type: "normal" },
  { id: 53, question: "What is the Singleton design pattern? Provide an example.", type: "normal" },
  { id: 54, question: "What is the Factory design pattern? Provide an example.", type: "normal" },
  { id: 55, question: "What is a Proxy design pattern? Provide an example.", type: "normal" },

  // Data Structures & Algorithms
  { id: 56, question: "Write a program to find the largest sum subarray using Kadane's Algorithm.", type: "coding" },
  { id: 57, question: "Implement Fibonacci using recursion and iteration.", type: "coding" },
  { id: 58, question: "Write a program to check if a string is a palindrome.", type: "coding" },
  { id: 59, question: "How do you find the longest palindromic substring in a given string?", type: "coding" },
  { id: 60, question: "Write a Java program to find if two strings are anagrams.", type: "coding" },
  { id: 61, question: "Write a program to reverse a linked list.", type: "coding" },
  { id: 62, question: "Find the second-largest element in an array.", type: "coding" },
  { id: 63, question: "Write a program to check if a number is prime.", type: "coding" },
  { id: 64, question: "Implement binary search in Java.", type: "coding" },
  { id: 65, question: "Write a program to merge two sorted arrays.", type: "coding" },
  { id: 66, question: "Find the shortest path in a graph using Dijkstra's Algorithm.", type: "coding" },
  { id: 67, question: "Implement a stack using arrays.", type: "coding" },
  { id: 68, question: "Implement a queue using linked lists.", type: "coding" },
  { id: 69, question: "Write a program to find the intersection of two arrays.", type: "coding" },
  { id: 70, question: "Write a program to implement bubble sort.", type: "coding" },
  { id: 71, question: "Write a program to find the maximum depth of a binary tree.", type: "coding" },
  { id: 72, question: "Implement a binary search tree in Java.", type: "coding" },
  { id: 73, question: "Write a program to find the nth Fibonacci number using dynamic programming.", type: "coding" },
  { id: 74, question: "Find the missing number in a sequence of integers.", type: "coding" },
  { id: 75, question: "Check if a binary tree is a binary search tree.", type: "coding" },
  { id: 76, question: "Write a program to count the number of islands in a matrix.", type: "coding" },
  { id: 77, question: "Write a Java program to find the largest connected component in a graph.", type: "coding" },
  { id: 78, question: "Write a program to find all subsets of a set.", type: "coding" },
  { id: 79, question: "Write a program to solve the N-Queens problem.", type: "coding" },
  { id: 80, question: "Write a program to find the maximum product subarray.", type: "coding" },

  // Java Frameworks & Libraries
  { id: 81, question: "What is the Java Collections Framework?", type: "normal" },
  { id: 82, question: "What is the difference between List, Set, and Map in Java?", type: "normal" },
  { id: 83, question: "Explain the HashMap internals in Java.", type: "normal" },
  { id: 84, question: "What is the difference between Comparable and Comparator?", type: "normal" },
  { id: 85, question: "What are the differences between ArrayList and LinkedList?", type: "normal" },
  { id: 86, question: "What is the purpose of a PriorityQueue in Java?", type: "normal" },
  { id: 87, question: "Explain the difference between TreeSet and HashSet.", type: "normal" },
  { id: 88, question: "What is the difference between ConcurrentHashMap and HashMap?", type: "normal" },
  { id: 89, question: "How does a LinkedHashMap work?", type: "normal" },
  { id: 90, question: "What is a WeakHashMap?", type: "normal" },
  { id: 91, question: "What is an Iterator? Explain its types.", type: "normal" },
  { id: 92, question: "What is the difference between Iterator and ListIterator?", type: "normal" },
  { id: 93, question: "What is a BlockingQueue in Java?", type: "normal" },
  { id: 94, question: "What is the difference between ConcurrentLinkedQueue and ArrayBlockingQueue?", type: "normal" },

  // Miscellaneous
  { id: 95, question: "What is Java Reflection API? Provide an example.", type: "normal" },
  { id: 96, question: "What is the purpose of annotations in Java?", type: "normal" },
  { id: 97, question: "What is the difference between Serialization and Externalization?", type: "normal" },
  { id: 98, question: "What is a ThreadLocal variable? Provide an example.", type: "normal" },
  { id: 99, question: "What is the difference between SoftReference, WeakReference, and PhantomReference?", type: "normal" },
  { id: 100, question: "What is the Java Memory Model? Explain its main components.", type: "normal" },

  // Memory Management & Performance
  { id: 101, question: "What are the causes of OutOfMemoryError in Java?", type: "normal" },
  { id: 102, question: "How can you prevent memory leaks in Java?", type: "normal" },
  { id: 103, question: "Explain the Java Heap and Stack memory model.", type: "normal" },
  { id: 104, question: "What is the difference between PermGen and Metaspace in Java?", type: "normal" },
  { id: 105, question: "What are the best practices for optimizing memory usage in Java applications?", type: "normal" },
  { id: 106, question: "What tools can be used for monitoring and analyzing JVM performance?", type: "normal" },
  { id: 107, question: "Explain how garbage collection works in Java and its impact on performance.", type: "normal" },
  { id: 108, question: "What are the differences between minor, major, and full garbage collection events?", type: "normal" },
  { id: 109, question: "How can you profile memory usage in a Java application?", type: "normal" },
  { id: 110, question: "What are memory-mapped files in Java, and how do they impact memory usage?", type: "normal" },

  // Tools & Utilities
  { id: 111, question: "What is JConsole, and how can it be used?", type: "normal" },
  { id: 112, question: "What is VisualVM, and how does it help in monitoring Java applications?", type: "normal" },
  { id: 113, question: "What is the purpose of the jmap tool in Java?", type: "normal" },
  { id: 114, question: "How can the jstack tool be used to diagnose performance issues?", type: "normal" },
  { id: 115, question: "What is the purpose of the Java Flight Recorder (JFR)?", type: "normal" },
  { id: 116, question: "What are the differences between JProfiler and YourKit?", type: "normal" },
  { id: 117, question: "Explain the use of Apache JMeter in Java application performance testing.", type: "normal" },
  { id: 118, question: "How does the Eclipse Memory Analyzer (MAT) work for debugging memory issues?", type: "normal" },
  { id: 119, question: "What is the purpose of the G1 garbage collector, and when should it be used?", type: "normal" },
  { id: 120, question: "What is the Z Garbage Collector (ZGC), and how does it differ from other collectors?", type: "normal" },

  { id: 121, question: "Explain the difference between fail-fast and fail-safe iterators.", type: "normal" },
  { id: 122, question: "What is the purpose of the transient keyword in serialization?", type: "normal" },
  { id: 123, question: "What is the difference between the == operator and the equals() method?", type: "normal" },
  { id: 124, question: "How can you make a Java class immutable?", type: "normal" },
  { id: 125, question: "What is the role of the JVM in garbage collection?", type: "normal" },
  { id: 126, question: "What are the different types of garbage collectors in Java?", type: "normal" },
  { id: 127, question: "What is the role of the ClassLoader in Java?", type: "normal" },
  { id: 128, question: "What is the difference between the equals() method and hashCode() method?", type: "normal" },
  { id: 129, question: "What are lambda expressions, and how do they work in Java?", type: "normal" },
  { id: 130, question: "What is the difference between StringBuilder and StringBuffer?", type: "normal" },
  { id: 131, question: "What is the Observer design pattern? Provide an example.", type: "normal" },
  { id: 132, question: "How does the Java Memory Model work?", type: "normal" },
  { id: 133, question: "What is the difference between System.out.println() and System.err.println()?", type: "normal" },
  { id: 134, question: "What is the purpose of the yield() method in multithreading?", type: "normal" },
  { id: 135, question: "What is the difference between the FileReader and FileInputStream classes?", type: "normal" },
  { id: 136, question: "What are the main differences between the java.util.Date and java.time.LocalDate classes?", type: "normal" },
  { id: 137, question: "What are the different types of locks available in Java?", type: "normal" },
  { id: 138, question: "What is the ExecutorService interface used for in Java?", type: "normal" },
  { id: 139, question: "What is the difference between the process and the thread in Java?", type: "normal" },
  { id: 140, question: "Explain the concept of a Singleton pattern in Java.", type: "normal" },
  { id: 141, question: "What is a deadlock, and how can you prevent it in Java?", type: "normal" },
  { id: 142, question: "What are the differences between ArrayList and Vector in Java?", type: "normal" },
  { id: 143, question: "What is the purpose of the synchronized keyword in Java?", type: "normal" },
  { id: 144, question: "What is the difference between public, private, and protected access modifiers?", type: "normal" },
  { id: 145, question: "What are the benefits of using the Java Collections Framework?", type: "normal" },
  { id: 146, question: "How does Java support exception handling?", type: "normal" },
  { id: 147, question: "What is the role of the synchronized block in Java?", type: "normal" },
  { id: 148, question: "Explain the difference between String, StringBuffer, and StringBuilder in Java.", type: "normal" },
  { id: 149, question: "What is the purpose of the volatile keyword in Java?", type: "normal" },
  { id: 150, question: "What is the difference between an abstract class and an interface in Java?", type: "normal" },

  // Advanced Multithreading and Concurrency
  { id: 151, question: "What is the purpose of the synchronized keyword in Java?", type: "normal" },
  { id: 152, question: "Explain how synchronized blocks differ from synchronized methods.", type: "normal" },
  { id: 153, question: "What is a reentrant lock? How does it differ from synchronized?", type: "normal" },
  { id: 154, question: "What is a thread-safe class? Provide examples.", type: "normal" },
  { id: 155, question: "How does the synchronized keyword affect performance?", type: "normal" },
  { id: 156, question: "What is the difference between wait(), notify(), and notifyAll()?", type: "normal" },
  { id: 157, question: "What are atomic operations in Java? Provide examples.", type: "normal" },
  { id: 158, question: "What is the Executors framework in Java?", type: "normal" },
  { id: 159, question: "Explain the difference between newFixedThreadPool and newCachedThreadPool.", type: "normal" },
  { id: 160, question: "What is a ScheduledExecutorService? Provide an example.", type: "normal" },
  { id: 161, question: "What are Callable and Future in Java? How are they used?", type: "normal" },
  { id: 162, question: "What is the difference between submit() and execute() methods in ExecutorService?", type: "normal" },
  { id: 163, question: "What is a CompletionService in Java?", type: "normal" },
  { id: 164, question: "What is a ForkJoinPool? How is it different from a regular thread pool?", type: "normal" },
  { id: 165, question: "Explain the work-stealing algorithm used by ForkJoinPool.", type: "normal" },
  { id: 166, question: "What are the advantages of using the Fork/Join framework?", type: "normal" },
  { id: 167, question: "How does RecursiveTask differ from RecursiveAction in Fork/Join?", type: "normal" },
  { id: 168, question: "What is the difference between invoke(), fork(), and join() in Fork/Join?", type: "normal" },
  { id: 169, question: "How does the CountDownLatch class work?", type: "normal" },
  { id: 170, question: "What is the purpose of the CyclicBarrier class?", type: "normal" },
  { id: 171, question: "What is the Phaser class in Java? How is it different from CyclicBarrier?", type: "normal" },
  { id: 172, question: "What is the purpose of a Semaphore in Java?", type: "normal" },
  { id: 173, question: "What is a ReentrantReadWriteLock? Provide an example.", type: "normal" },
  { id: 174, question: "What is the difference between ConcurrentHashMap and HashMap?", type: "normal" },
  { id: 175, question: "How does a ConcurrentLinkedQueue work?", type: "normal" },
  { id: 176, question: "What is the CopyOnWriteArrayList? When should it be used?", type: "normal" },
  { id: 177, question: "What are the main classes in the java.util.concurrent package?", type: "normal" },
  { id: 178, question: "How does the BlockingQueue interface work? Provide examples.", type: "normal" },
  { id: 179, question: "What is the difference between ArrayBlockingQueue and LinkedBlockingQueue?", type: "normal" },
  { id: 180, question: "What is the purpose of the DelayQueue in Java?", type: "normal" },
  { id: 181, question: "What is a PriorityBlockingQueue? Provide an example.", type: "normal" },
  { id: 182, question: "What is a ConcurrentSkipListMap in Java? How is it used?", type: "normal" },
  { id: 183, question: "What are the differences between synchronized collections and concurrent collections?", type: "normal" },
  { id: 184, question: "What is the StampedLock class in Java?", type: "normal" },
  { id: 185, question: "How does the Exchanger class work in multithreading?", type: "normal" },
  { id: 186, question: "What is the purpose of the ThreadPoolExecutor class?", type: "normal" },
  { id: 187, question: "What is the difference between a single-threaded executor and a fixed-thread pool?", type: "normal" },
  { id: 188, question: "What is the LinkedTransferQueue in Java?", type: "normal" },
  { id: 189, question: "How can you shut down an ExecutorService gracefully?", type: "normal" },
  { id: 190, question: "What is the main advantage of ForkJoinPool over traditional thread pools?", type: "normal" },
  { id: 191, question: "What is the difference between ForkJoinTask and RecursiveTask?", type: "normal" },
  { id: 192, question: "How do you detect and prevent deadlocks in Java?", type: "normal" },
  { id: 193, question: "What are thread-safe collections in Java?", type: "normal" },
  { id: 194, question: "Explain the purpose of the ThreadFactory interface.", type: "normal" },
  { id: 195, question: "How does the FutureTask class work in Java?", type: "normal" },
  { id: 196, question: "What is the purpose of the ConcurrentMap interface?", type: "normal" },
  { id: 197, question: "What is a ThreadLocalRandom, and when should it be used?", type: "normal" },
  { id: 198, question: "What is the role of ForkJoinWorkerThread in the Fork/Join framework?", type: "normal" },
  { id: 199, question: "Explain how a Phaser can be used for dynamic thread management.", type: "normal" },
  { id: 200, question: "What is the advantage of using the java.util.concurrent package?", type: "normal" },
  // Scenario-based Questions (Post ID 210)
  { id: 211, question: "You are building a real-time messaging application where users send and receive messages instantaneously. How would you ensure that the system scales and handles large numbers of concurrent users?", type: "normal" },
  { id: 212, question: "A client requires a feature that enables users to store documents in the cloud and access them from multiple devices. How would you implement this feature while ensuring data consistency and security?", type: "normal" },
  { id: 213, question: "You are working on a microservices architecture and need to implement a centralized logging system that collects logs from all services. How would you design this solution in Java?", type: "normal" },
  { id: 214, question: "In an e-commerce application, you need to implement a discount system where users can apply different discounts to their orders. How would you handle this feature, ensuring that the discounts are applied correctly?", type: "normal" },
  { id: 215, question: "You are working on a ride-sharing application and need to implement a matching algorithm that pairs drivers and passengers based on proximity and availability. How would you design and implement this algorithm?", type: "normal" },
  { id: 216, question: "A client needs to implement a search feature for an online store where users can search for products by category, price range, and brand. How would you design this feature to optimize performance for large product catalogs?", type: "normal" },
  { id: 217, question: "You need to implement a notification system that sends real-time alerts to users of a financial application when a transaction is made. How would you ensure the reliability and performance of this notification system?", type: "normal" },
  { id: 218, question: "In a healthcare application, you are tasked with handling sensitive patient data. How would you ensure compliance with regulations such as HIPAA while implementing features like data storage and communication?", type: "normal" },
  { id: 219, question: "You are tasked with designing a recommendation engine for a music streaming service that suggests playlists based on user preferences and listening history. How would you build this system in Java?", type: "normal" },
  { id: 220, question: "Your team is working on a large-scale data analytics system that processes huge amounts of data from various sources. How would you handle data ingestion, processing, and storage efficiently in a Java-based system?", type: "normal" },
  { id: 221, question: "You are building a supply chain management system, and you need to ensure that inventory levels are tracked and updated in real-time across different locations. How would you design this system in Java?", type: "normal" },
  { id: 222, question: "In an online gaming platform, you need to design a system to ensure that player scores are updated and synchronized across multiple servers. How would you ensure the consistency and reliability of the scoring system?", type: "normal" },
  { id: 223, question: "You are tasked with creating a secure authentication and authorization system for an enterprise application. How would you implement features like multi-factor authentication and role-based access control in Java?", type: "normal" },
  { id: 224, question: "A client needs to implement an order processing system where orders are created, processed, and shipped in a sequence. How would you design the system to ensure each step is handled correctly and the system remains scalable?", type: "normal" },
  { id: 225, question: "In an automated trading platform, you need to implement a real-time risk assessment feature that analyzes trades and alerts traders about high-risk transactions. How would you design and implement this feature?", type: "normal" },
  { id: 226, question: "You are developing an API for an online store, and it needs to support both JSON and XML formats. How would you implement a solution to handle both formats efficiently in Java?", type: "normal" },
  { id: 227, question: "Your team is developing a Java-based video streaming application, and you need to implement a feature that allows users to stream videos in multiple resolutions based on their internet speed. How would you design this feature?", type: "normal" },
  { id: 228, question: "You are tasked with developing a backup system for a large file storage service. The system needs to regularly back up data and ensure that users can restore files to a previous version. How would you approach the design of this backup system?", type: "normal" },
  { id: 229, question: "In a customer support system, you need to implement a feature that allows agents to handle multiple support tickets concurrently, while ensuring data consistency across tickets. How would you design the system in Java?", type: "normal" },
  { id: 230, question: "You are working on a collaborative platform where multiple users can edit documents in real-time. How would you ensure that changes are synchronized across users while avoiding conflicts in the document?", type: "normal" },
  // Additional Scenario-based Questions
  { id: 231, question: "You are building a document management system where users can upload, categorize, and search through a large collection of files. How would you ensure efficient file storage and retrieval?", type: "normal" },
  { id: 232, question: "You need to implement an event-driven architecture in a Java-based system where microservices communicate asynchronously through events. How would you ensure that events are processed reliably and in order?", type: "normal" },
  { id: 233, question: "You are working on a social media platform and need to implement a feature where users can follow others and receive updates when they post new content. How would you design this feature while handling scalability?", type: "normal" },
  { id: 234, question: "Your company is developing a video conferencing system. How would you design the system to handle real-time audio and video communication, and ensure low latency and good performance?", type: "normal" },
  { id: 235, question: "In a warehouse management system, you need to implement a feature where products are automatically reordered when stock levels fall below a certain threshold. How would you design this system to ensure timely and accurate reordering?", type: "normal" },
  { id: 236, question: "You are building a recommendation system for a news website where users get personalized content recommendations. How would you design the recommendation algorithm and ensure its performance for a large user base?", type: "normal" },
  { id: 237, question: "You need to implement a file upload system in your application that supports large files, such as videos or images. How would you ensure that the file upload process is efficient and reliable, even for large files?", type: "normal" },
  { id: 238, question: "You are tasked with designing a system that tracks employee attendance and generates reports based on their working hours. How would you ensure that the system is accurate and can handle large amounts of data?", type: "normal" },
  { id: 239, question: "You are designing a financial application where users can perform transactions between accounts. How would you ensure that all transactions are processed atomically and without race conditions?", type: "normal" },
  { id: 240, question: "You are working on a file-sharing system where users can upload and download files. How would you implement a versioning system that allows users to track changes and revert to previous versions of files?", type: "normal" },
  { id: 241, question: "Your team is tasked with implementing a location-based service that helps users find nearby restaurants. How would you design the system to efficiently query nearby locations and ensure accurate results?", type: "normal" },
  { id: 242, question: "You are building a mobile app for an e-commerce platform that includes a shopping cart. How would you ensure that the cart data is synced across multiple devices for the same user?", type: "normal" },
  { id: 243, question: "In a customer loyalty program, users earn points based on their purchases. How would you design the system to track and redeem loyalty points while ensuring data accuracy and preventing fraud?", type: "normal" },
  { id: 244, question: "You need to create a content moderation system for a community-driven platform where users can post content. How would you implement a system that can automatically flag inappropriate content while handling edge cases?", type: "normal" },
  { id: 245, question: "Your team is designing a secure messaging app, and you need to ensure that messages are encrypted during transit and at rest. How would you implement end-to-end encryption and key management in the system?", type: "normal" },
  { id: 246, question: "You are designing a fitness app that tracks users' workout routines. How would you ensure that the app can handle large volumes of data while maintaining performance, and allow users to analyze their progress over time?", type: "normal" },
  { id: 247, question: "You are tasked with designing a tax calculation system for an e-commerce platform. The system needs to calculate taxes based on the user's location, item type, and other factors. How would you approach this problem?", type: "normal" },
  { id: 248, question: "You need to design an API for an online banking system that allows users to transfer money between accounts. How would you ensure that the API is secure, efficient, and can handle concurrent requests?", type: "normal" },
  { id: 249, question: "In an online marketplace, you need to build a feedback system that allows users to rate sellers. How would you design this system to handle high volumes of feedback and ensure fairness in the ratings?", type: "normal" },
  { id: 250, question: "You are designing a payment gateway for an e-commerce platform. How would you implement fraud detection to identify and prevent unauthorized transactions while ensuring a seamless user experience?", type: "normal" },
 // Stream API Coding Questions
 { id: 251, question: "How can you filter a list of integers to get only even numbers using Stream API?", type: "coding" },
 { id: 252, question: "Write a code to convert a list of strings to uppercase using Stream API.", type: "coding" },
 { id: 253, question: "How can you calculate the sum of a list of integers using Stream API?", type: "coding" },
 { id: 254, question: "Write a code to find the average of a list of numbers using Stream API.", type: "coding" },
 { id: 255, question: "How do you collect the elements of a Stream into a List?", type: "coding" },
 { id: 256, question: "Write a program to get the distinct elements from a Stream of numbers.", type: "coding" },
 { id: 257, question: "How do you sort a list of strings in ascending order using Stream API?", type: "coding" },
 { id: 258, question: "How can you map a list of strings to their lengths using Stream API?", type: "coding" },
 { id: 259, question: "Write a code to check if all elements in a Stream are greater than 10.", type: "coding" },
 { id: 260, question: "How can you find the maximum value in a list of integers using Stream API?", type: "coding" },
 { id: 261, question: "Write a program to count the occurrences of each word in a list using Stream API.", type: "coding" },
 { id: 262, question: "Write a program to filter a list of employees based on their age using Stream API.", type: "coding" },
 { id: 263, question: "How do you use flatMap to flatten a list of lists in Java Stream API?", type: "coding" },
 { id: 264, question: "Write a code to find the first element in a Stream that is greater than a specific value.", type: "coding" },
 { id: 265, question: "How can you perform a reduction operation to calculate the product of numbers in a Stream?", type: "coding" },
 { id: 266, question: "Write a program to partition a list of integers into odd and even using Stream API.", type: "coding" },
 { id: 267, question: "Write a program to group a list of employees by their department using Stream API.", type: "coding" },
 { id: 268, question: "How can you create a Stream from a String?", type: "coding" },
 { id: 269, question: "Write a code to remove null values from a Stream of objects.", type: "coding" },
 { id: 270, question: "How can you use Stream API to find the longest string in a list of strings?", type: "coding" },
 { id: 271, question: "Write a code to find if any element in a Stream matches a given condition.", type: "coding" },
 { id: 272, question: "How do you use Stream API to skip the first N elements of a Stream?", type: "coding" },
 { id: 273, question: "Write a program to find the number of unique words in a list of strings using Stream API.", type: "coding" },
 { id: 274, question: "How do you sort a list of integers in descending order using Stream API?", type: "coding" },
 { id: 275, question: "Write a code to map a list of integers to their square roots using Stream API.", type: "coding" },
 { id: 276, question: "How do you find the first element that satisfies a condition in a Stream?", type: "coding" },
 { id: 277, question: "Write a program to concatenate a list of strings into a single string using Stream API.", type: "coding" },
 { id: 278, question: "How can you check if a Stream is empty in Java?", type: "coding" },
 { id: 279, question: "Write a program to reverse the order of elements in a Stream.", type: "coding" },
 { id: 280, question: "How do you combine multiple Streams into a single Stream?", type: "coding" },
 { id: 281, question: "Write a code to sort a list of integers in ascending order and then find the sum of the first three elements using Stream API.", type: "coding" },
 { id: 282, question: "How can you map a list of integers to their corresponding even/odd classification using Stream API?", type: "coding" },
 { id: 283, question: "Write a program to find the count of elements in a Stream that meet a certain condition.", type: "coding" },
 { id: 284, question: "How can you remove duplicates from a list of strings using Stream API?", type: "coding" },
 { id: 285, question: "Write a program to find the sum of even numbers in a list using Stream API.", type: "coding" },
 { id: 286, question: "How can you perform a map operation and filter in a single chain?", type: "coding" },
 { id: 287, question: "Write a program to filter a list of numbers based on a given range using Stream API.", type: "coding" },
 { id: 288, question: "How do you find the average of even numbers in a Stream?", type: "coding" },
 { id: 289, question: "Write a program to combine a list of names into a single comma-separated string using Stream API.", type: "coding" },
 { id: 290, question: "How do you use Stream API to implement a custom comparator for sorting objects?", type: "coding" },
 { id: 291, question: "Write a program to create a map of employees with their names as the key and age as the value using Stream API.", type: "coding" },
 { id: 292, question: "How can you merge multiple lists into one list using Stream API?", type: "coding" },
 { id: 293, question: "Write a program to find the minimum number in a Stream of integers.", type: "coding" },
 { id: 294, question: "How can you filter a Stream of objects based on a particular field?", type: "coding" },
 { id: 295, question: "Write a code to partition a list of integers into two groups based on a condition using Stream API.", type: "coding" },
 { id: 296, question: "How do you limit the number of elements in a Stream?", type: "coding" },
 { id: 297, question: "Write a program to extract unique words from a list of sentences using Stream API.", type: "coding" },
 { id: 298, question: "How can you sort a Stream of objects based on multiple fields?", type: "coding" },
 { id: 299, question: "Write a program to flatten a Stream of lists into a single Stream of elements.", type: "coding" },
 { id: 300, question: "How do you generate a Stream of numbers from a given range?", type: "coding" },
 { id: 301, question: "Write a program to check if a Stream contains all elements from a given list of items.", type: "coding" },
 { id: 302, question: "How can you convert a Stream to a Map with a custom key and value?", type: "coding" },
 { id: 303, question: "Write a program to find the longest string from a list of strings using Stream API.", type: "coding" },
 { id: 304, question: "How do you perform a conditional mapping in a Stream?", type: "coding" },
 { id: 305, question: "Write a program to check if any element in a Stream is divisible by 3.", type: "coding" },
 { id: 306, question: "How can you use a custom comparator to sort a Stream of strings by their length?", type: "coding" },
 { id: 307, question: "Write a program to calculate the factorial of a number using Stream API.", type: "coding" },
 { id: 308, question: "How can you group elements by their length using Stream API?", type: "coding" },
 { id: 309, question: "Write a program to filter a Stream of objects by multiple attributes using Stream API.", type: "coding" },
 { id: 310, question: "How do you use Stream API to implement an operation that works on multiple collections at once?", type: "coding" },
];


export default JAVA_QUESTIONS