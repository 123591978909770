import JAVA_QUESTIONS from './JAVA_QUESTIONS';
import SPRINGBOOT_QUESTIONS from './SPRINGBOOT_QUESTIONS';
import REACTJS_QUESTIONS from './REACTJS_QUESTIONS';
import REACTNATIVE_QUESTIONS from './REACTNATIVE_QUESTIONS';
import JAVASCRIPT_QUESTIONS from './JAVASCRIPT_QUESTIONS';
import FLUTTER_QUESTIONS from './FLUTTER_QUESTIONS';
import NODEJS_QUESTIONS from './NODEJS_QUESTIONS';
import AWS_MICROSERVICES_KAFKA from './AWS_Microservices_Kafka';

const QUESTIONS = {
  java: JAVA_QUESTIONS,
  springBoot: SPRINGBOOT_QUESTIONS,
  reactJS: REACTJS_QUESTIONS,
  reactNative: REACTNATIVE_QUESTIONS,
  javascript: JAVASCRIPT_QUESTIONS,
  flutter: FLUTTER_QUESTIONS,
  nodejs: NODEJS_QUESTIONS,
  awsMicroservicesKafka: AWS_MICROSERVICES_KAFKA
};

export default QUESTIONS;
