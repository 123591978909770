const JAVASCRIPT_QUESTIONS = [
  // Normal JavaScript Questions
  { id: 1, question: "What are the main features of JavaScript?", type: "normal" },
  { id: 2, question: "What is the difference between `var`, `let`, and `const` in JavaScript?", type: "normal" },
  { id: 3, question: "Explain the concept of hoisting in JavaScript.", type: "normal" },
  { id: 4, question: "What is the purpose of closures in JavaScript?", type: "normal" },
  { id: 5, question: "What is the event loop in JavaScript?", type: "normal" },
  { id: 6, question: "What are JavaScript data types?", type: "normal" },
  { id: 7, question: "What is the difference between `null` and `undefined`?", type: "normal" },
  { id: 8, question: "Explain the difference between `==` and `===` in JavaScript.", type: "normal" },
  { id: 9, question: "What is the `this` keyword in JavaScript?", type: "normal" },
  { id: 10, question: "What are callbacks in JavaScript?", type: "normal" },
  { id: 11, question: "What is the use of the `bind()` method in JavaScript?", type: "normal" },
  { id: 12, question: "What are promises in JavaScript?", type: "normal" },
  { id: 13, question: "What is async/await in JavaScript?", type: "normal" },
  { id: 14, question: "Explain the difference between synchronous and asynchronous code in JavaScript.", type: "normal" },
  { id: 15, question: "What is event delegation in JavaScript?", type: "normal" },
  { id: 16, question: "What are JavaScript modules?", type: "normal" },
  { id: 17, question: "What is the purpose of the `typeof` operator in JavaScript?", type: "normal" },
  { id: 18, question: "What are the array methods `map()`, `filter()`, and `reduce()` used for?", type: "normal" },
  { id: 19, question: "Explain prototypal inheritance in JavaScript.", type: "normal" },
  { id: 20, question: "What is the purpose of the `new` keyword in JavaScript?", type: "normal" },

  // Coding Questions for JavaScript
  { id: 21, question: "Write a function to reverse a string in JavaScript.", type: "coding" },
  { id: 22, question: "Write a function to find the largest number in an array.", type: "coding" },
  { id: 23, question: "Write a function to check if a string is a palindrome.", type: "coding" },
  { id: 24, question: "Write a function that removes duplicates from an array.", type: "coding" },
  { id: 25, question: "Write a function to count the occurrences of each character in a string.", type: "coding" },
  { id: 26, question: "Write a function to merge two sorted arrays into a single sorted array.", type: "coding" },
  { id: 27, question: "Write a function to find the longest word in a string.", type: "coding" },
  { id: 28, question: "Write a function to check if an array contains duplicate elements.", type: "coding" },
  { id: 29, question: "Write a function to rotate an array by `k` steps.", type: "coding" },
  { id: 30, question: "Write a function to find the intersection of two arrays.", type: "coding" },
  { id: 31, question: "Write a function that accepts a number and returns its factorial.", type: "coding" },
  { id: 32, question: "Write a function to check if a number is prime.", type: "coding" },
  { id: 33, question: "Write a function to sort an array of strings in alphabetical order.", type: "coding" },
  { id: 34, question: "Write a function to flatten a nested array.", type: "coding" },
  { id: 35, question: "Write a function to find the missing number in an array of consecutive numbers.", type: "coding" },

  // ES6 Features
  { id: 36, question: "What are the new features introduced in ES6?", type: "normal" },
  { id: 37, question: "What is the difference between arrow functions and regular functions in ES6?", type: "normal" },
  { id: 38, question: "What is destructuring in ES6? Give an example.", type: "normal" },
  { id: 39, question: "What are template literals in ES6?", type: "normal" },
  { id: 40, question: "What is the spread operator in ES6? Provide an example.", type: "normal" },
  { id: 41, question: "What is the rest operator in ES6?", type: "normal" },
  { id: 42, question: "What are default parameters in ES6?", type: "normal" },
  { id: 43, question: "What is the `Map` object in ES6?", type: "normal" },
  { id: 44, question: "What is the `Set` object in ES6?", type: "normal" },
  { id: 45, question: "What is the `class` syntax in ES6?", type: "normal" },
  { id: 46, question: "What are modules in ES6?", type: "normal" },

  // String and Array Coding Questions
  { id: 47, question: "Write a function to concatenate two arrays without using `concat()`.", type: "coding" },
  { id: 48, question: "Write a function to remove falsy values from an array.", type: "coding" },
  { id: 49, question: "Write a function to check if an array is a subset of another array.", type: "coding" },
  { id: 50, question: "Write a function to find the most frequent element in an array.", type: "coding" },
  { id: 51, question: "Write a function to reverse the words in a string.", type: "coding" },
  { id: 52, question: "Write a function to find all the unique characters in a string.", type: "coding" },
  { id: 53, question: "Write a function to compare two arrays for equality.", type: "coding" },
  { id: 54, question: "Write a function to perform a shallow copy of an array.", type: "coding" },
  { id: 55, question: "Write a function to capitalize the first letter of each word in a string.", type: "coding" },
  { id: 56, question: "Write a function to flatten an object (nested properties into flat ones).", type: "coding" },

  // TypeScript Questions
  { id: 57, question: "What is TypeScript and how is it different from JavaScript?", type: "normal" },
  { id: 58, question: "What is the purpose of type annotations in TypeScript?", type: "normal" },
  { id: 59, question: "Explain the concept of interfaces in TypeScript.", type: "normal" },
  { id: 60, question: "What are generics in TypeScript? Give an example.", type: "normal" },
  { id: 61, question: "What is the `never` type in TypeScript?", type: "normal" },
  { id: 62, question: "How does TypeScript handle optional properties?", type: "normal" },
  { id: 63, question: "What are type assertions in TypeScript?", type: "normal" },
  { id: 64, question: "What is the difference between `any` and `unknown` in TypeScript?", type: "normal" },
  { id: 65, question: "What are enums in TypeScript?", type: "normal" },
  { id: 66, question: "What is the purpose of `readonly` modifier in TypeScript?", type: "normal" },
  { id: 67, question: "What is the difference between `interface` and `type` in TypeScript?", type: "normal" },
  { id: 68, question: "What is the purpose of `strict` mode in TypeScript?", type: "normal" },

  // TypeScript Coding Questions
  { id: 69, question: "Write a function in TypeScript that accepts an array of numbers and returns the sum of the numbers.", type: "coding" },
  { id: 70, question: "Write a TypeScript function that accepts a string and returns its uppercase version.", type: "coding" },
  { id: 71, question: "Write a TypeScript function that takes an array and returns a new array with each element squared.", type: "coding" },
  { id: 72, question: "Write a TypeScript function that accepts an object with properties `firstName` and `lastName` and returns a string with the full name.", type: "coding" },
  { id: 73, question: "Write a TypeScript function that accepts an array of strings and returns a string concatenating all the strings with a space.", type: "coding" },
  { id: 74, question: "Write a TypeScript function that accepts an array of objects with a `name` property and returns an array of names.", type: "coding" },
  { id: 75, question: "Write a TypeScript function to calculate the average of numbers in an array.", type: "coding" },
  { id: 76, question: "Write a TypeScript function that finds the factorial of a number.", type: "coding" },
  { id: 77, question: "Write a TypeScript function that checks if a number is a prime number.", type: "coding" },
  { id: 78, question: "Write a TypeScript function that checks if a string contains only alphabets.", type: "coding" },

  // Advanced TypeScript Questions
  { id: 79, question: "What are mapped types in TypeScript?", type: "normal" },
  { id: 80, question: "Explain the `keyof` and `typeof` operators in TypeScript.", type: "normal" },
  { id: 81, question: "What is type inference in TypeScript?", type: "normal" },
  { id: 82, question: "What is `union` type in TypeScript?", type: "normal" },
  { id: 83, question: "What is `intersection` type in TypeScript?", type: "normal" },
  { id: 84, question: "What are type guards in TypeScript?", type: "normal" },
  { id: 85, question: "What is the `asserts` keyword in TypeScript?", type: "normal" },
  { id: 86, question: "What is the `infer` keyword in TypeScript?", type: "normal" },
  { id: 87, question: "What are conditional types in TypeScript?", type: "normal" },
  { id: 88, question: "Explain `literal types` in TypeScript.", type: "normal" },

  // Miscellaneous Questions
  { id: 89, question: "What are pure functions?", type: "normal" },
  { id: 90, question: "What is a higher-order function?", type: "normal" },
  { id: 91, question: "What are closures in JavaScript?", type: "normal" },
  { id: 92, question: "What is currying?", type: "normal" },
  { id: 93, question: "What are the differences between `apply()` and `call()` methods?", type: "normal" },
  { id: 94, question: "What is memoization?", type: "normal" },
  { id: 95, question: "What are promises in JavaScript?", type: "normal" },
  { id: 96, question: "What is a single-page application (SPA)?", type: "normal" },
  { id: 97, question: "What is cross-origin resource sharing (CORS)?", type: "normal" },
  { id: 98, question: "What is the difference between `window.location` and `window.location.href`?", type: "normal" },
  { id: 99, question: "Explain the term AJAX in the context of web development.", type: "normal" },
  { id: 100, question: "What is the difference between `localStorage` and `sessionStorage`?", type: "normal" },
];


export default JAVASCRIPT_QUESTIONS