const NODEJS_QUESTIONS = [
  // Normal Questions
  { id: 1, question: "What is Node.js, and why is it used?", type: "normal" },
  { id: 2, question: "What are the main features of Node.js?", type: "normal" },
  { id: 3, question: "What is the difference between Node.js and JavaScript?", type: "normal" },
  { id: 4, question: "What is the purpose of the `package.json` file in a Node.js project?", type: "normal" },
  { id: 5, question: "What are modules in Node.js, and how are they used?", type: "normal" },
  { id: 6, question: "What is the role of the `fs` module in Node.js?", type: "normal" },
  { id: 7, question: "What is the difference between synchronous and asynchronous programming in Node.js?", type: "normal" },
  { id: 8, question: "What is an event loop in Node.js, and how does it work?", type: "normal" },
  { id: 9, question: "What is middleware in Node.js, and how is it used?", type: "normal" },
  { id: 10, question: "What are streams in Node.js, and what are their types?", type: "normal" },
  { id: 11, question: "What is the difference between `process.nextTick` and `setImmediate` in Node.js?", type: "normal" },
  { id: 12, question: "How is error handling implemented in Node.js?", type: "normal" },
  { id: 13, question: "What is the purpose of the `cluster` module in Node.js?", type: "normal" },
  { id: 14, question: "What is the `buffer` module in Node.js, and how is it used?", type: "normal" },
  { id: 15, question: "What is the difference between `require` and `import` in Node.js?", type: "normal" },
  { id: 16, question: "What is the purpose of the `util` module in Node.js?", type: "normal" },
  { id: 17, question: "What is the purpose of the `crypto` module in Node.js?", type: "normal" },
  { id: 18, question: "What is the difference between `npm` and `yarn` package managers?", type: "normal" },
  { id: 19, question: "What are the common built-in modules in Node.js?", type: "normal" },
  { id: 20, question: "What is the role of `dotenv` in a Node.js application?", type: "normal" },

  // Scenario-based Interview Questions
  { id: 21, question: "How would you handle a scenario where you need to implement real-time communication in Node.js using WebSockets?", type: "normal" },
  { id: 22, question: "In a scenario where you need to handle large file uploads, how would you optimize Node.js to handle multiple concurrent uploads?", type: "normal" },
  { id: 23, question: "How would you manage a scenario where you need to set up authentication and authorization in a Node.js API with JWT?", type: "normal" },
  { id: 24, question: "What approach would you take in a scenario where your Node.js application needs to handle high traffic with optimal performance?", type: "normal" },
  { id: 25, question: "In a scenario where you need to create a REST API in Node.js, how would you structure the application and ensure scalability?", type: "normal" },
  { id: 26, question: "How would you approach a scenario where you need to integrate a third-party payment gateway (e.g., Stripe) in a Node.js application?", type: "normal" },
  { id: 27, question: "In a scenario where you need to optimize the performance of a Node.js application that handles a large number of database queries, how would you approach this?", type: "normal" },
  { id: 28, question: "How would you implement a scenario where your Node.js application needs to interact with multiple external APIs asynchronously?", type: "normal" },
  { id: 29, question: "In a scenario where your Node.js application needs to support file streaming (audio or video), how would you implement it?", type: "normal" },
  { id: 30, question: "How would you handle a scenario where your Node.js application needs to support cron jobs for recurring tasks?", type: "normal" },
  { id: 31, question: "What would be your approach to ensuring that sensitive data is securely handled in a Node.js application?", type: "normal" },
  { id: 32, question: "In a scenario where your Node.js app must support OAuth2 authentication with Google and Facebook, how would you implement it?", type: "normal" },
  { id: 33, question: "How would you handle a scenario where you need to implement rate-limiting in a Node.js API to prevent abuse?", type: "normal" },
  { id: 34, question: "In a scenario where your Node.js application needs to handle multiple types of requests (e.g., JSON, XML), how would you set up the routing and parsing?", type: "normal" },
  { id: 35, question: "How would you optimize a scenario where your Node.js application handles real-time data with low latency, such as for chat or gaming?", type: "normal" },
  { id: 36, question: "In a scenario where your Node.js application must be containerized using Docker, how would you set up the Docker environment for efficient scaling?", type: "normal" },
  { id: 37, question: "How would you handle a scenario where your Node.js application needs to cache frequent queries to reduce load on the database?", type: "normal" },
  { id: 38, question: "In a scenario where your Node.js application needs to integrate with a MongoDB database, how would you set up the connection and handle data efficiently?", type: "normal" },
  { id: 39, question: "How would you handle a scenario where your Node.js application needs to ensure that tasks are processed in order (like message queues)?", type: "normal" },
  { id: 40, question: "In a scenario where your Node.js application needs to handle large-scale data processing asynchronously, how would you implement this in a scalable manner?", type: "normal" },
  { id: 41, question: "How would you set up logging in a Node.js application for production environments to monitor and troubleshoot issues?", type: "normal" },
  { id: 42, question: "In a scenario where your Node.js application needs to integrate with a Redis cache to manage session data, how would you implement this?", type: "normal" },
  { id: 43, question: "How would you handle a scenario where your Node.js application needs to ensure high availability by using load balancing?", type: "normal" },
  { id: 44, question: "In a scenario where your Node.js application needs to handle long-running tasks (e.g., file processing), how would you implement it without blocking the event loop?", type: "normal" },
  { id: 45, question: "How would you approach a scenario where your Node.js application needs to provide RESTful API documentation for developers using Swagger?", type: "normal" },
  { id: 46, question: "In a scenario where your Node.js application must support cross-origin resource sharing (CORS), how would you configure it?", type: "normal" },
  { id: 47, question: "How would you implement a scenario where your Node.js application needs to manage multiple environments (e.g., development, staging, production)?", type: "normal" },
  { id: 48, question: "How would you handle a scenario where you need to schedule background tasks in a Node.js application using libraries like Bull or Agenda?", type: "normal" },
  { id: 49, question: "In a scenario where your Node.js application needs to use WebSockets for bidirectional communication with clients, how would you implement it?", type: "normal" },
  { id: 50, question: "How would you handle a scenario where your Node.js application needs to implement version control for its REST APIs?", type: "normal" },

  // Additional Questions
  { id: 51, question: "How would you use Node.js to create a file system watcher that monitors changes in a directory?", type: "normal" },
  { id: 52, question: "Explain the event-driven nature of Node.js and how it benefits high-performance applications?", type: "normal" },
  { id: 53, question: "How does Node.js handle concurrency, and what is the role of the event loop in this process?", type: "normal" },
  { id: 54, question: "What are callback functions in Node.js, and how are they used in asynchronous programming?", type: "normal" },
  { id: 55, question: "Can you explain the concept of 'callback hell' in Node.js, and how would you handle it?", type: "normal" },
  { id: 56, question: "What are Promises in Node.js, and how do they differ from callbacks?", type: "normal" },
  { id: 57, question: "What is the `async/await` syntax in Node.js, and how does it improve asynchronous code readability?", type: "normal" },
  { id: 58, question: "How do you handle security concerns like SQL injection and XSS in a Node.js web application?", type: "normal" },
  { id: 59, question: "What is the `path` module in Node.js, and how do you use it to manipulate file paths?", type: "normal" },
  { id: 60, question: "How do you deploy a Node.js application to a cloud platform like AWS or Heroku?", type: "normal" },
// Normal Questions
{ id: 61, question: "What is the difference between `exports` and `module.exports` in Node.js?", type: "normal" },
{ id: 62, question: "What is a callback function in Node.js, and why is it important?", type: "normal" },
{ id: 63, question: "What is the role of `npm` in Node.js?", type: "normal" },
{ id: 64, question: "How can you use Node.js to create a simple HTTP server?", type: "normal" },
{ id: 65, question: "What is an `EventEmitter` in Node.js, and how do you use it?", type: "normal" },
{ id: 66, question: "What is a Promise in Node.js, and how does it work?", type: "normal" },
{ id: 67, question: "What is the difference between `process.env` and `process.argv` in Node.js?", type: "normal" },
{ id: 68, question: "How would you prevent blocking the event loop in Node.js?", type: "normal" },
{ id: 69, question: "What are the different types of streams in Node.js?", type: "normal" },
{ id: 70, question: "How do you manage package dependencies in a Node.js application?", type: "normal" },
{ id: 71, question: "What are the differences between `var`, `let`, and `const` in Node.js?", type: "normal" },
{ id: 72, question: "What is the purpose of the `assert` module in Node.js?", type: "normal" },
{ id: 73, question: "How would you handle file uploads in a Node.js application?", type: "normal" },
{ id: 74, question: "Explain the role of the `http` module in Node.js.", type: "normal" },
{ id: 75, question: "What are the advantages of using Node.js for building server-side applications?", type: "normal" },
{ id: 76, question: "What is a singleton pattern, and how would you implement it in Node.js?", type: "normal" },
{ id: 77, question: "How would you set up a basic logging mechanism in Node.js?", type: "normal" },
{ id: 78, question: "How do you handle uncaught exceptions and unhandled promise rejections in Node.js?", type: "normal" },
{ id: 79, question: "What is the difference between `fs.readFileSync()` and `fs.readFile()`?", type: "normal" },
{ id: 80, question: "What is the purpose of the `process` object in Node.js?", type: "normal" },

// Scenario-based Interview Questions
{ id: 81, question: "In a scenario where you need to implement a real-time messaging app, how would you use Node.js and WebSockets to achieve this?", type: "normal" },
{ id: 82, question: "How would you handle a scenario where a Node.js app needs to deal with multiple background jobs running concurrently?", type: "normal" },
{ id: 83, question: "What approach would you take in a scenario where you need to handle a heavy workload (like image processing) in a Node.js application without blocking the event loop?", type: "normal" },
{ id: 84, question: "How would you implement authentication in a Node.js REST API using JWT and passport.js?", type: "normal" },
{ id: 85, question: "In a scenario where you need to process multiple requests in parallel (e.g., scraping multiple websites), how would you manage concurrency in Node.js?", type: "normal" },
{ id: 86, question: "In a scenario where you need to implement a Node.js service that communicates with a MySQL database, what libraries or tools would you use?", type: "normal" },
{ id: 87, question: "How would you implement a retry mechanism for a failed API call in a Node.js application?", type: "normal" },
{ id: 88, question: "In a scenario where you need to update multiple records in a database within a single transaction, how would you handle this in Node.js?", type: "normal" },
{ id: 89, question: "How would you handle a situation where your Node.js application needs to scale horizontally across multiple servers?", type: "normal" },
{ id: 90, question: "In a scenario where you need to ensure the availability of your Node.js application with load balancing, how would you implement it?", type: "normal" },
{ id: 91, question: "What steps would you take in a scenario where your Node.js application needs to be deployed on multiple environments (dev, test, prod)?", type: "normal" },
{ id: 92, question: "How would you handle a scenario where a Node.js application must handle large-scale user requests (e.g., a popular social media platform)?", type: "normal" },
{ id: 93, question: "In a scenario where your Node.js application needs to handle long-running tasks, how would you manage timeouts and retries?", type: "normal" },
{ id: 94, question: "What is your approach for creating a CI/CD pipeline for a Node.js application?", type: "normal" },
{ id: 95, question: "How would you integrate a caching layer (e.g., Redis) in a Node.js application to improve performance?", type: "normal" },
{ id: 96, question: "In a scenario where your Node.js app needs to connect to multiple microservices, how would you manage the API calls and handle failures?", type: "normal" },
{ id: 97, question: "How would you handle a scenario where a Node.js application needs to serve static files like images and CSS?", type: "normal" },
{ id: 98, question: "How would you implement data validation and sanitization in a Node.js API to prevent security vulnerabilities?", type: "normal" },
{ id: 99, question: "In a scenario where your Node.js application needs to log sensitive data (e.g., user details), how would you secure these logs?", type: "normal" },
{ id: 100, question: "What would be your approach to deploying a Node.js application using Docker and Kubernetes?", type: "normal" },
{ id: 101, question: "How would you implement rate-limiting and throttling in a Node.js API to avoid abuse?", type: "normal" },
{ id: 102, question: "In a scenario where you need to integrate with an external API in a Node.js application, how would you manage error handling and retries?", type: "normal" },
{ id: 103, question: "How would you handle a scenario where your Node.js application needs to ensure data consistency across multiple databases?", type: "normal" },
{ id: 104, question: "In a scenario where you need to create a microservices-based architecture with Node.js, how would you ensure seamless communication between services?", type: "normal" },
{ id: 105, question: "What steps would you take to monitor and troubleshoot performance issues in a Node.js application?", type: "normal" },
{ id: 106, question: "In a scenario where your Node.js application must handle a large volume of data, how would you optimize memory management?", type: "normal" },
{ id: 107, question: "How would you implement an API versioning strategy in a Node.js application?", type: "normal" },
{ id: 108, question: "In a scenario where you need to support user authentication across multiple devices in a Node.js app, how would you implement token-based authentication?", type: "normal" },
{ id: 109, question: "How would you implement a file download feature in a Node.js application, ensuring the file is served efficiently?", type: "normal" },
{ id: 110, question: "In a scenario where your Node.js application needs to interact with a NoSQL database like MongoDB, how would you handle large-scale data operations?", type: "normal" },
// Normal Questions
{ id: 111, question: "What is the purpose of the `child_process` module in Node.js?", type: "normal" },
{ id: 112, question: "What are the different ways to handle asynchronous operations in Node.js?", type: "normal" },
{ id: 113, question: "What is the `domain` module in Node.js, and how is it used?", type: "normal" },
{ id: 114, question: "What is the difference between a `fork` and `spawn` in Node.js?", type: "normal" },
{ id: 115, question: "What are the different types of errors in Node.js, and how do you handle them?", type: "normal" },
{ id: 116, question: "What are process management tools in Node.js, and why are they important?", type: "normal" },
{ id: 117, question: "What is the purpose of the `url` module in Node.js?", type: "normal" },
{ id: 118, question: "What is the difference between `req.query` and `req.params` in Node.js?", type: "normal" },
{ id: 119, question: "How would you handle Cross-Origin Resource Sharing (CORS) in a Node.js application?", type: "normal" },
{ id: 120, question: "What is the `path` module in Node.js, and how is it used?", type: "normal" },

// Scenario-based Interview Questions
{ id: 121, question: "In a scenario where your Node.js app needs to handle a large number of incoming requests, how would you implement load balancing?", type: "normal" },
{ id: 122, question: "How would you implement a caching layer in Node.js to improve response times for frequently accessed data?", type: "normal" },
{ id: 123, question: "In a scenario where you have a large Node.js application with many dependencies, how would you manage the application's performance?", type: "normal" },
{ id: 124, question: "How would you integrate a Node.js application with a message queue (like RabbitMQ or Kafka) for asynchronous processing?", type: "normal" },
{ id: 125, question: "In a scenario where you need to implement a REST API in Node.js, what best practices would you follow?", type: "normal" },
{ id: 126, question: "How would you handle a situation where your Node.js application requires real-time data streaming, like for a live sports score API?", type: "normal" },
{ id: 127, question: "What approach would you take to implement a rate-limiting mechanism in a Node.js application to protect against DDoS attacks?", type: "normal" },
{ id: 128, question: "In a scenario where your Node.js app needs to process and store large amounts of data (e.g., user-uploaded files), how would you handle storage efficiently?", type: "normal" },
{ id: 129, question: "How would you implement logging and monitoring for a Node.js application that is deployed in a production environment?", type: "normal" },
{ id: 130, question: "In a scenario where your Node.js app needs to interact with multiple APIs, how would you handle error propagation and retries?", type: "normal" },
{ id: 131, question: "What steps would you take in a scenario where you need to scale a Node.js application horizontally across multiple servers?", type: "normal" },
{ id: 132, question: "How would you implement server-side rendering (SSR) in a Node.js application?", type: "normal" },
{ id: 133, question: "In a scenario where you have to build a real-time chat application, what technologies in the Node.js ecosystem would you use?", type: "normal" },
{ id: 134, question: "In a scenario where your Node.js application needs to integrate with a third-party payment gateway (like Stripe or PayPal), how would you manage security?", type: "normal" },
{ id: 135, question: "How would you handle a scenario where your Node.js application needs to authenticate users across different systems (e.g., OAuth2 or OpenID Connect)?", type: "normal" },
{ id: 136, question: "In a scenario where your Node.js application needs to support multiple environments (dev, staging, prod), how would you configure environment variables securely?", type: "normal" },
{ id: 137, question: "How would you implement a task queue in Node.js for handling background jobs?", type: "normal" },
{ id: 138, question: "In a scenario where you need to implement a real-time notifications system in a Node.js app, how would you do it?", type: "normal" },
{ id: 139, question: "In a scenario where your Node.js app needs to handle both JSON and form data (like file uploads), how would you process the data efficiently?", type: "normal" },
{ id: 140, question: "How would you implement a feature to prevent brute-force login attempts in a Node.js application?", type: "normal" },

{ id: 141, question: "How would you handle a scenario where a Node.js application needs to handle heavy computational tasks, such as image processing?", type: "normal" },
{ id: 142, question: "How would you implement an API rate-limiting strategy for a public API to ensure it doesn't get overwhelmed by requests?", type: "normal" },
{ id: 143, question: "In a scenario where you need to build a microservices architecture, how would you design a Node.js service to communicate with other services?", type: "normal" },
{ id: 144, question: "How would you implement session management in a Node.js app that supports both client-side and server-side sessions?", type: "normal" },
{ id: 145, question: "In a scenario where you need to store data in a NoSQL database like MongoDB, how would you structure your Node.js application for optimal performance?", type: "normal" },
{ id: 146, question: "How would you handle a scenario where your Node.js application needs to handle concurrent requests for the same resource without causing data corruption?", type: "normal" },
{ id: 147, question: "What approach would you take to handle concurrent database queries in a Node.js application to avoid blocking the event loop?", type: "normal" },
{ id: 148, question: "In a scenario where your Node.js app needs to interact with a distributed file system like HDFS or Amazon S3, how would you manage file access?", type: "normal" },
{ id: 149, question: "In a scenario where you need to implement logging for both development and production environments, how would you structure logging in Node.js?", type: "normal" },
{ id: 150, question: "How would you implement automatic scaling of a Node.js application when deployed in a cloud environment (e.g., AWS, Azure)?", type: "normal" }
];


export default NODEJS_QUESTIONS