const REACTNATIVE_QUESTIONS = [
 // Normal Questions
 { id: 1, question: "What is React Native and how does it work?", type: "normal" },
 { id: 2, question: "What are the differences between React and React Native?", type: "normal" },
 { id: 3, question: "What is the purpose of `useEffect` in React Native?", type: "normal" },
 { id: 4, question: "What are the core components in React Native?", type: "normal" },
 { id: 5, question: "What is the `StyleSheet` in React Native?", type: "normal" },
 { id: 6, question: "What are props and state in React Native?", type: "normal" },
 { id: 7, question: "What is the difference between `state` and `props` in React Native?", type: "normal" },
 { id: 8, question: "How does React Native handle navigation?", type: "normal" },
 { id: 9, question: "What is the purpose of the `useState` hook in React Native?", type: "normal" },
 { id: 10, question: "How do you handle async operations in React Native?", type: "normal" },
 { id: 11, question: "What is `React Navigation` in React Native?", type: "normal" },
 { id: 12, question: "What are `TouchableOpacity`, `TouchableWithoutFeedback`, and `TouchableHighlight` in React Native?", type: "normal" },
 { id: 13, question: "What is the purpose of `FlatList` in React Native?", type: "normal" },
 { id: 14, question: "What are `Modal` and `Alert` in React Native?", type: "normal" },
 { id: 15, question: "What is `React Native CLI` and how is it different from `Expo`?", type: "normal" },
 { id: 16, question: "How do you style components in React Native?", type: "normal" },
 { id: 17, question: "What is the purpose of `useEffect` in React Native?", type: "normal" },
 { id: 18, question: "How can you improve performance in React Native apps?", type: "normal" },
 { id: 19, question: "What is the role of `redux` in React Native?", type: "normal" },
 { id: 20, question: "What is the purpose of the `Linking` module in React Native?", type: "normal" },

 // Additional React Native Interview Questions
 { id: 41, question: "What are the advantages of using React Native over native development?", type: "normal" },
 { id: 42, question: "What are the limitations of React Native?", type: "normal" },
 { id: 43, question: "What is the difference between React Native and Flutter?", type: "normal" },
 { id: 44, question: "What is the difference between `React Navigation` and `React Router`?", type: "normal" },
 { id: 45, question: "How do you handle push notifications in React Native?", type: "normal" },
 { id: 46, question: "How do you manage app state in React Native?", type: "normal" },
 { id: 47, question: "What is `redux-saga` and how does it work in React Native?", type: "normal" },
 { id: 48, question: "What are the differences between `AsyncStorage` and `SecureStorage` in React Native?", type: "normal" },
 { id: 49, question: "How do you handle debugging in React Native?", type: "normal" },
 { id: 50, question: "How do you optimize performance in React Native apps?", type: "normal" },
 { id: 51, question: "What is the difference between `Expo` and `React Native CLI`?", type: "normal" },
 { id: 52, question: "How do you integrate native modules in React Native?", type: "normal" },
 { id: 53, question: "How do you handle deep linking in React Native?", type: "normal" },
 { id: 54, question: "What is `React Native Web` and how does it work?", type: "normal" },
 { id: 55, question: "What is the role of `NativeBase` in React Native?", type: "normal" },
 { id: 56, question: "What is `react-native-paper`?", type: "normal" },
 { id: 57, question: "How do you implement background tasks in React Native?", type: "normal" },
 { id: 58, question: "What is the `React Native Debugger`?", type: "normal" },
 { id: 59, question: "What are the differences between functional components and class components in React Native?", type: "normal" },
 { id: 60, question: "How does React Native handle offline data?", type: "normal" },

 // More Advanced Topics
 { id: 61, question: "How do you handle animations in React Native?", type: "normal" },
 { id: 62, question: "What are `Native Modules` and how do you create them?", type: "normal" },
 { id: 63, question: "How do you handle gestures in React Native?", type: "normal" },
 { id: 64, question: "What is the role of `react-native-firebase`?", type: "normal" },
 { id: 65, question: "What are the steps involved in publishing a React Native app to the App Store or Google Play?", type: "normal" },
 { id: 66, question: "How do you perform unit testing in React Native?", type: "normal" },
 { id: 67, question: "How do you handle different screen sizes and orientations in React Native?", type: "normal" },
 { id: 68, question: "How do you handle versioning in React Native?", type: "normal" },
 { id: 69, question: "What are the different ways to store data in React Native?", type: "normal" },
 { id: 70, question: "What is the `react-native link` command used for?", type: "normal" },
 { id: 71, question: "How do you integrate third-party libraries in React Native?", type: "normal" },
 { id: 72, question: "What is `react-navigation` and how does it work?", type: "normal" },
 { id: 73, question: "What is the purpose of the `Android` and `iOS` folders in a React Native project?", type: "normal" },
 { id: 74, question: "How do you handle app crashes in React Native?", type: "normal" },
 { id: 75, question: "What is the use of the `react-native-async-storage` library?", type: "normal" },
 // Normal Questions (Continued)
 { id: 76, question: "What are the key benefits of using React Native for mobile app development?", type: "normal" },
 { id: 77, question: "What is the difference between `useEffect` and `useLayoutEffect` in React Native?", type: "normal" },
 { id: 78, question: "What are the advantages of using hooks like `useState` and `useEffect` in React Native?", type: "normal" },
 { id: 79, question: "How does the React Native app communicate with native modules?", type: "normal" },
 { id: 80, question: "What are some ways to optimize images in React Native to reduce memory usage?", type: "normal" },
 { id: 81, question: "How can you handle app updates in React Native apps?", type: "normal" },
 { id: 82, question: "What is the `react-native-reanimated` library, and how does it differ from `Animated` in React Native?", type: "normal" },
 { id: 83, question: "What is the purpose of `react-native-navigation` and how does it differ from `React Navigation`?", type: "normal" },
 { id: 84, question: "How does `react-native-firebase` work with Firebase for push notifications?", type: "normal" },
 { id: 85, question: "How can you integrate third-party libraries in React Native?", type: "normal" },
 { id: 86, question: "What is the role of `Jest` and `Enzyme` in testing React Native applications?", type: "normal" },
 { id: 87, question: "How do you handle deep linking in React Native?", type: "normal" },
 { id: 88, question: "What is `Expo SDK` and what are the main features of Expo?", type: "normal" },
 { id: 89, question: "What are `React Native's Native Modules` and how are they created?", type: "normal" },
 { id: 90, question: "How does React Native handle platform-specific code?", type: "normal" },
 { id: 91, question: "How do you implement local storage or persistent storage in React Native?", type: "normal" },
 { id: 92, question: "What are some best practices for improving the performance of React Native apps?", type: "normal" },
 { id: 93, question: "What is `React Native Web`?", type: "normal" },
 { id: 94, question: "How can you implement device-specific features, such as camera access, in React Native?", type: "normal" },
 { id: 95, question: "What is the role of `Redux` in managing state in React Native?", type: "normal" },
 { id: 96, question: "What are the pros and cons of using `Redux` in a React Native app?", type: "normal" },
 { id: 97, question: "What are some strategies for handling background processes and notifications in React Native?", type: "normal" },
 { id: 98, question: "What is the `react-native-sqlite-storage` library and how do you use it for offline storage?", type: "normal" },
 { id: 99, question: "How can you handle animations in React Native to make the app feel more native?", type: "normal" },
 { id: 100, question: "What is `react-native-gesture-handler` and how is it used?", type: "normal" },
 { id: 101, question: "What is the `useRef` hook in React Native, and when should it be used?", type: "normal" },
 { id: 102, question: "What is the purpose of `AppRegistry` in React Native?", type: "normal" },
 { id: 103, question: "What are the different navigation types available in React Native?", type: "normal" },
 { id: 104, question: "What are the main differences between `react-navigation` and `react-native-navigation`?", type: "normal" },
 { id: 105, question: "How can you test React Native components?", type: "normal" },
 { id: 106, question: "How do you implement custom animations in React Native?", type: "normal" },
 { id: 107, question: "What is the `Linking` module in React Native and how is it used?", type: "normal" },
 { id: 108, question: "How can you handle error boundaries in React Native?", type: "normal" },
 { id: 109, question: "What is the purpose of `Hermes` and how does it optimize React Native performance?", type: "normal" },
 { id: 110, question: "What are some debugging tools you can use with React Native?", type: "normal" },
// Normal Questions (Continued)
{ id: 111, question: "What are the differences between `Expo` and `React Native CLI`?", type: "normal" },
{ id: 112, question: "How do you manage application state in React Native?", type: "normal" },
{ id: 113, question: "How do you handle security in React Native apps?", type: "normal" },
{ id: 114, question: "What is the purpose of the `react-native-paper` library?", type: "normal" },
{ id: 115, question: "How do you implement background tasks in React Native?", type: "normal" },
{ id: 116, question: "How do you create a custom component in React Native?", type: "normal" },
{ id: 117, question: "What is `react-native-vector-icons` and how do you use it?", type: "normal" },
{ id: 118, question: "What are the most common performance issues in React Native and how do you solve them?", type: "normal" },
{ id: 119, question: "What is the `Metro bundler` in React Native?", type: "normal" },
{ id: 120, question: "How do you implement offline functionality in React Native?", type: "normal" },
{ id: 121, question: "What is `react-native-reanimated` and how does it improve performance?", type: "normal" },
{ id: 122, question: "What is `react-native-maps` and how do you use it?", type: "normal" },
{ id: 123, question: "What is the purpose of `react-native-firebase`?", type: "normal" },
{ id: 124, question: "How do you handle deep linking in React Native?", type: "normal" },
{ id: 125, question: "What is the `react-native-sound` library and how can you use it in your app?", type: "normal" },
{ id: 126, question: "How do you enable push notifications in React Native?", type: "normal" },
{ id: 127, question: "How can you implement a splash screen in React Native?", type: "normal" },
{ id: 128, question: "What is the role of `react-navigation` in React Native?", type: "normal" },
{ id: 129, question: "What is `react-native-sqlite-storage` and how do you use it?", type: "normal" },
{ id: 130, question: "How do you manage network requests in React Native?", type: "normal" },
{ id: 131, question: "What is `Redux Toolkit` and how is it used in React Native?", type: "normal" },
{ id: 132, question: "How do you implement custom gestures in React Native?", type: "normal" },
{ id: 133, question: "What are `native modules` and how do you integrate them in React Native?", type: "normal" },
{ id: 134, question: "What is `Hermes` and how does it improve React Native performance?", type: "normal" },
{ id: 135, question: "How do you handle form validation in React Native?", type: "normal" },
{ id: 136, question: "What is `react-native-webview` and how do you use it?", type: "normal" },
{ id: 137, question: "What are the challenges when building React Native apps for both iOS and Android?", type: "normal" },
{ id: 138, question: "How do you optimize images in React Native?", type: "normal" },
{ id: 139, question: "How do you handle multi-threading in React Native?", type: "normal" },
{ id: 140, question: "What is `react-native-background-fetch` and how can it be used?", type: "normal" },
{ id: 141, question: "How do you manage authentication in React Native?", type: "normal" },
{ id: 142, question: "What are some techniques for handling large datasets in React Native?", type: "normal" },
{ id: 143, question: "How can you handle gestures and animations with `react-native-gesture-handler`?", type: "normal" },
{ id: 144, question: "How do you implement a drawer navigation in React Native?", type: "normal" },
{ id: 145, question: "What is `react-native-testing-library` and how is it used?", type: "normal" },
{ id: 146, question: "How can you implement theming in React Native?", type: "normal" },
{ id: 147, question: "What are the benefits of using TypeScript with React Native?", type: "normal" },
{ id: 148, question: "How do you implement custom transitions in React Native?", type: "normal" },
{ id: 149, question: "What is `react-native-permissions` and how can it be used?", type: "normal" },
{ id: 150, question: "How do you handle animations in React Native using the `Animated API`?", type: "normal" },
// Normal Questions (Continued)
  { id: 151, question: "What is `react-native-navigation` and how does it differ from `react-navigation`?", type: "normal" },
  { id: 152, question: "What are some ways to optimize the startup time of a React Native app?", type: "normal" },
  { id: 153, question: "How do you handle dynamic content updates in React Native?", type: "normal" },
  { id: 154, question: "What is the difference between `useEffect` and `componentDidMount` in React Native?", type: "normal" },
  { id: 155, question: "What is `react-native-blur` and how is it used?", type: "normal" },
  { id: 156, question: "How do you handle memory leaks in React Native?", type: "normal" },
  { id: 157, question: "What is the purpose of the `react-native-device-info` library?", type: "normal" },
  { id: 158, question: "How can you improve the performance of list rendering in React Native?", type: "normal" },
  { id: 159, question: "How do you manage background tasks in React Native?", type: "normal" },
  { id: 160, question: "What is `react-native-background-task` and how can it be used?", type: "normal" },
  { id: 161, question: "How do you enable native code debugging in React Native?", type: "normal" },
  { id: 162, question: "What is `react-native-reanimated` and how is it different from `Animated` API?", type: "normal" },
  { id: 163, question: "What are `react-native-async-storage` and `react-native-mmkv`?", type: "normal" },
  { id: 164, question: "How do you use `react-native-splash-screen` to show splash screens?", type: "normal" },
  { id: 165, question: "What are `react-native-lottie` animations and how do you use them?", type: "normal" },
  { id: 166, question: "What is `react-native-push-notification` and how is it used in React Native?", type: "normal" },
  { id: 167, question: "How can you handle touch gestures in React Native?", type: "normal" },
  { id: 168, question: "What is `react-native-maps` and how do you implement custom map markers?", type: "normal" },
  { id: 169, question: "How do you handle app localization in React Native?", type: "normal" },
  { id: 170, question: "What is the purpose of the `react-native-permissions` library?", type: "normal" },
  { id: 171, question: "How do you handle crash reporting in React Native?", type: "normal" },
  { id: 172, question: "What is `react-native-camera` and how do you use it?", type: "normal" },
  { id: 173, question: "What are the differences between a stateful and stateless component in React Native?", type: "normal" },
  { id: 174, question: "How do you use the `Alert` component in React Native?", type: "normal" },
  { id: 175, question: "How do you implement Google Maps in a React Native app?", type: "normal" },
  { id: 176, question: "What is `react-native-vector-icons` and why is it useful?", type: "normal" },
  { id: 177, question: "How can you implement a search bar in React Native?", type: "normal" },
  { id: 178, question: "What is the `TouchableNativeFeedback` component and how is it different from `TouchableOpacity`?", type: "normal" },
  { id: 179, question: "What are some common performance optimizations in React Native?", type: "normal" },
  { id: 180, question: "How do you create and manage a custom theme in React Native?", type: "normal" },
  { id: 181, question: "How do you use the `ImageBackground` component in React Native?", type: "normal" },
  { id: 182, question: "How do you manage routes in a React Native app?", type: "normal" },
  { id: 183, question: "How do you integrate Google Sign-In in React Native?", type: "normal" },
  { id: 184, question: "What is the `react-native-debugger` and how do you use it?", type: "normal" },
  { id: 185, question: "How do you handle network errors in React Native?", type: "normal" },
  { id: 186, question: "How do you implement a custom status bar in React Native?", type: "normal" },
  { id: 187, question: "What is `react-native-web` and how does it work?", type: "normal" },
  { id: 188, question: "What is `react-native-fs` and how do you use it for file management?", type: "normal" },
  { id: 189, question: "How do you handle authentication and authorization in React Native?", type: "normal" },
  { id: 190, question: "How do you implement a deep linking strategy in React Native?", type: "normal" },
  { id: 191, question: "What is `react-native-iap` and how is it used?", type: "normal" },
  { id: 192, question: "How do you implement custom notifications in React Native?", type: "normal" },
  { id: 193, question: "What is the purpose of `react-native-fetch-blob`?", type: "normal" },
  { id: 194, question: "How do you manage large images in React Native?", type: "normal" },
  { id: 195, question: "How can you optimize the build process for React Native apps?", type: "normal" },
  { id: 196, question: "How do you handle app permissions in React Native?", type: "normal" },
  { id: 197, question: "What is `react-native-firebase` and how do you use it for push notifications?", type: "normal" },
  { id: 198, question: "What is the purpose of the `React Native AsyncStorage`?", type: "normal" },
  { id: 199, question: "How do you implement accessibility in React Native?", type: "normal" },
  { id: 200, question: "What are `context` and `useContext` in React Native?", type: "normal" },
// Normal Questions (Continued)
{ id: 151, question: "What is `react-native-navigation` and how does it differ from `react-navigation`?", type: "normal" },
{ id: 152, question: "What are some ways to optimize the startup time of a React Native app?", type: "normal" },
{ id: 153, question: "How do you handle dynamic content updates in React Native?", type: "normal" },
{ id: 154, question: "What is the difference between `useEffect` and `componentDidMount` in React Native?", type: "normal" },
{ id: 155, question: "What is `react-native-blur` and how is it used?", type: "normal" },
{ id: 156, question: "How do you handle memory leaks in React Native?", type: "normal" },
{ id: 157, question: "What is the purpose of the `react-native-device-info` library?", type: "normal" },
{ id: 158, question: "How can you improve the performance of list rendering in React Native?", type: "normal" },
{ id: 159, question: "How do you manage background tasks in React Native?", type: "normal" },
{ id: 160, question: "What is `react-native-background-task` and how can it be used?", type: "normal" },
{ id: 161, question: "How do you enable native code debugging in React Native?", type: "normal" },
{ id: 162, question: "What is `react-native-reanimated` and how is it different from `Animated` API?", type: "normal" },
{ id: 163, question: "What are `react-native-async-storage` and `react-native-mmkv`?", type: "normal" },
{ id: 164, question: "How do you use `react-native-splash-screen` to show splash screens?", type: "normal" },
{ id: 165, question: "What are `react-native-lottie` animations and how do you use them?", type: "normal" },
{ id: 166, question: "What is `react-native-push-notification` and how is it used in React Native?", type: "normal" },
{ id: 167, question: "How can you handle touch gestures in React Native?", type: "normal" },
{ id: 168, question: "What is `react-native-maps` and how do you implement custom map markers?", type: "normal" },
{ id: 169, question: "How do you handle app localization in React Native?", type: "normal" },
{ id: 170, question: "What is the purpose of the `react-native-permissions` library?", type: "normal" },
{ id: 171, question: "How do you handle crash reporting in React Native?", type: "normal" },
{ id: 172, question: "What is `react-native-camera` and how do you use it?", type: "normal" },
{ id: 173, question: "What are the differences between a stateful and stateless component in React Native?", type: "normal" },
{ id: 174, question: "How do you use the `Alert` component in React Native?", type: "normal" },
{ id: 175, question: "How do you implement Google Maps in a React Native app?", type: "normal" },
{ id: 176, question: "What is `react-native-vector-icons` and why is it useful?", type: "normal" },
{ id: 177, question: "How can you implement a search bar in React Native?", type: "normal" },
{ id: 178, question: "What is the `TouchableNativeFeedback` component and how is it different from `TouchableOpacity`?", type: "normal" },
{ id: 179, question: "What are some common performance optimizations in React Native?", type: "normal" },
{ id: 180, question: "How do you create and manage a custom theme in React Native?", type: "normal" },
{ id: 181, question: "How do you use the `ImageBackground` component in React Native?", type: "normal" },
{ id: 182, question: "How do you manage routes in a React Native app?", type: "normal" },
{ id: 183, question: "How do you integrate Google Sign-In in React Native?", type: "normal" },
{ id: 184, question: "What is the `react-native-debugger` and how do you use it?", type: "normal" },
{ id: 185, question: "How do you handle network errors in React Native?", type: "normal" },
{ id: 186, question: "How do you implement a custom status bar in React Native?", type: "normal" },
{ id: 187, question: "What is `react-native-web` and how does it work?", type: "normal" },
{ id: 188, question: "What is `react-native-fs` and how do you use it for file management?", type: "normal" },
{ id: 189, question: "How do you handle authentication and authorization in React Native?", type: "normal" },
{ id: 190, question: "How do you implement a deep linking strategy in React Native?", type: "normal" },
{ id: 191, question: "What is `react-native-iap` and how is it used?", type: "normal" },
{ id: 192, question: "How do you implement custom notifications in React Native?", type: "normal" },
{ id: 193, question: "What is the purpose of `react-native-fetch-blob`?", type: "normal" },
{ id: 194, question: "How do you manage large images in React Native?", type: "normal" },
{ id: 195, question: "How can you optimize the build process for React Native apps?", type: "normal" },
{ id: 196, question: "How do you handle app permissions in React Native?", type: "normal" },
{ id: 197, question: "What is `react-native-firebase` and how do you use it for push notifications?", type: "normal" },
{ id: 198, question: "What is the purpose of the `React Native AsyncStorage`?", type: "normal" },
{ id: 199, question: "How do you implement accessibility in React Native?", type: "normal" },
{ id: 200, question: "What are `context` and `useContext` in React Native?", type: "normal" },
];

export default REACTNATIVE_QUESTIONS