import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import botImage from './assets/bot.gif';
import listeningImage from './assets/listening.gif';
import writingImage from './assets/writing.gif';
import QUESTIONS from './Questions/QUESTIONS';
import timerConfig from './config/timerConfig'

function App() {

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isInterviewStarted, setIsInterviewStarted] = useState(false);
  const [timer, setTimer] = useState(30 * 60); // Default to 30 minutes
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isQuestionSpoken, setIsQuestionSpoken] = useState(false);
  const [recognizedText, setRecognizedText] = useState('');
  const [pauseTimer, setPauseTimer] = useState(timerConfig.normal);
  const [selectedTopic, setSelectedTopic] = useState('java');
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(30); // New state for selected interview duration
  const videoRef = useRef(null);
  const recognitionRef = useRef(null);

  // Update the question list when the selected topic changes
  useEffect(() => {
    const initialQuestions = shuffleArray(QUESTIONS[selectedTopic]); // Get questions dynamically based on the selected topic
    setShuffledQuestions(initialQuestions);
  }, [selectedTopic]);

  useEffect(() => {
    if (isInterviewStarted) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isInterviewStarted]);

  useEffect(() => {
    if (isInterviewStarted && !isSpeaking && !isQuestionSpoken) {
      speakQuestion();
    }
  }, [currentQuestionIndex, isInterviewStarted, isSpeaking, isQuestionSpoken]);

  const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

  const handleTopicChange = (event) => {
    const topic = event.target.value;
    setSelectedTopic(topic);
  };

  const handleDurationChange = (event) => {
    const duration = event.target.value;
    setSelectedDuration(duration);
    setTimer(duration * 60); // Update timer when the duration changes
  };

  const nextQuestion = () => {
    if (currentQuestionIndex + 1 < shuffledQuestions.length) {
      const nextIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(nextIndex);
      setIsQuestionSpoken(false);
      setIsListening(false);
      setRecognizedText('');

      // Get the current question type and set pauseTimer accordingly
      const nextQuestionType = shuffledQuestions[nextIndex]?.type;
      if (nextQuestionType === 'coding') {
        setPauseTimer(timerConfig.coding);
      } else {
        setPauseTimer(timerConfig.normal);
      }
    } else {
      alert('You have answered all the questions!');
      window.location.reload();
    }
  };

  const speakQuestion = () => {
    const question = shuffledQuestions[currentQuestionIndex]?.question; // Accessing the question from the object
    const questionType = shuffledQuestions[currentQuestionIndex]?.type; // Get the type of the current question
    const speechSynthesis = window.speechSynthesis;
    const speech = new SpeechSynthesisUtterance(question);

    setIsSpeaking(true);
    setIsListening(false);
    setRecognizedText('');

    if (questionType === 'coding') {
      setPauseTimer(timerConfig.coding);
    } else {
      setPauseTimer(timerConfig.normal);
    }

    speech.onend = () => {
      setIsSpeaking(false);
      setIsQuestionSpoken(true);
      setIsListening(true);
      startListening();  // Start listening only after the question is fully spoken
    };

    speechSynthesis.speak(speech);
  };


  const startInterview = () => {
    setIsInterviewStarted(true);
    speakQuestion();
    openCamera();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error accessing the camera:", err);
    }
  };

  const startListening = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.lang = 'en-US';
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;

      recognitionRef.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        setRecognizedText(transcript);
        setPauseTimer(10);
      };

      recognitionRef.current.onend = () => {
        if (isListening) recognitionRef.current.start();
      };

      recognitionRef.current.start();
    }
  };

  useEffect(() => {
    let pauseInterval;
    if (isListening) {
      pauseInterval = setInterval(() => {
        setPauseTimer(prev => {
          if (prev <= 1) {
            clearInterval(pauseInterval);
            nextQuestion();
            return timerConfig.normal;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(pauseInterval);
  }, [isListening, pauseTimer]);

  return (
    <div className="App">
      <div className="container-fluid d-flex flex-column">
        <div className="d-flex flex-column flex-md-row" style={{ flex: 1 }}>
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ flex: 1 }}>
            <div className="text-center">
              {!isInterviewStarted ? (
                <>
                  <select onChange={handleTopicChange} style={{ cursor: 'pointer', marginTop: 16 }} className="form-select mb-3" value={selectedTopic}>
                    <option value="java">Java</option>
                    <option value="springBoot">Spring Boot</option>
                    <option value="reactJS">React JS</option>
                    <option value="reactNative">React Native</option>
                    <option value="javascript">JavaScript</option>
                    <option value="flutter">Flutter</option>
                    <option value="nodejs">Node JS</option>
                    <option value="awsMicroservicesKafka">AWS-Microservices-Kafka</option>
                  </select>
                  <select onChange={handleDurationChange} style={{ width: '100%', cursor: 'pointer' }} className="form-select mb-3" value={selectedDuration}>
                    <option value={30}>30 Minutes</option>
                    <option value={45}>45 Minutes</option>
                    <option value={60}>60 Minutes</option>
                  </select>
                  <button onClick={startInterview} style={{ marginTop: 20 }} className="btn btn-primary mb-3 w-100">
                    Start Interview
                  </button>
                </>
              ) : (
                <>
                  {isInterviewStarted && isSpeaking && <img src={botImage} height={300} width={300} alt="Bot" />}
                  {isInterviewStarted && isListening && !isSpeaking && (
                    <>
                      {
                        shuffledQuestions[currentQuestionIndex]?.type == "coding" ?
                          <img src={writingImage} height={180} className="listening-image" width={180} style={{
                            padding: 16,
                            borderRadius: 100
                          }} alt="Listening" />
                          : <img src={listeningImage} height={180}
                            className="listening-image" width={180} style={{ padding: 16 }} alt="Listening" />
                      }

                      {pauseTimer < shuffledQuestions[currentQuestionIndex]?.type == "coding" ? timerConfig.coding : timerConfig.normal && <p style={{ color: 'red', backgroundColor: 'white', fontSize: 16, alignItems: 'center', alignSelf: 'center', width: 200 }}>Next question: {pauseTimer} {shuffledQuestions[currentQuestionIndex]?.type == "coding"?" (5 Minutes)":""}</p>}
                    </>
                  )}
                  <p style={{ fontSize: 16, textAlign: 'left' }}>Q{currentQuestionIndex + 1}. {shuffledQuestions[currentQuestionIndex]?.question}</p>

                  {
                    shuffledQuestions[currentQuestionIndex]?.type == "normal" && recognizedText != "" ?
                    <p
                      style={{
                        fontSize: 12,
                        textAlign: 'left',
                        maxHeight: 150, // Limits the vertical height
                        maxWidth: 400,  // Limits the horizontal width
                        overflow: 'auto', // Enables scrolling
                        whiteSpace: 'pre-wrap', // Preserves spaces and wraps text
                        border: '1px solid #ccc', // Optional: Adds a border for better visibility
                        padding: '10px', // Optional: Adds padding inside the container
                        backgroundColor: '#f9f9f9' // Optional: Background for readability
                      }}
                    >
                      {recognizedText}
                    </p>:<></>
                  }

                  {
                    shuffledQuestions[currentQuestionIndex]?.type == "coding" &&
                    <button className='btn btn-primary' onClick={nextQuestion}>Save/Skip question</button>
                  }
                </>
              )}
            </div>

            {isInterviewStarted && (
              <div className="mt-3" style={{ position: 'absolute', right: 32, top: 32, backgroundColor: 'red', paddingTop: 6, paddingLeft: 16, paddingRight: 16, height: 35, borderRadius:4 }}>
                <div className="remaining-time">
                  <p style={{ color: 'white', fontSize: 16 }}>{formatTime(timer)}</p>
                </div>
              </div>
            )}
          </div>

          {/* Video Section */}
          <div className="d-flex justify-content-center align-items-center" style={{ flex: 1 }}>
            {!isInterviewStarted ? (
              <img src={botImage} height={200} width={200} alt="Bot" />
            ) : (
              <div className="video-container">
                <video ref={videoRef} autoPlay muted></video>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
