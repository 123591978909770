const FLUTTER_QUESTIONS = [
  // Normal Questions
  { id: 1, question: "What is Flutter, and how does it differ from other frameworks?", type: "normal" },
  { id: 2, question: "Explain the architecture of Flutter and its key components.", type: "normal" },
  { id: 3, question: "What is a Widget in Flutter, and how is it different from a Component in React?", type: "normal" },
  { id: 4, question: "What are StatelessWidget and StatefulWidget in Flutter?", type: "normal" },
  { id: 5, question: "Explain the Widget lifecycle in Flutter.", type: "normal" },
  { id: 6, question: "What is the difference between Hot Reload and Hot Restart in Flutter?", type: "normal" },
  { id: 7, question: "What is the purpose of the BuildContext in Flutter?", type: "normal" },
  { id: 8, question: "What are keys in Flutter, and why are they important?", type: "normal" },
  { id: 9, question: "What are the main types of animations in Flutter?", type: "normal" },
  { id: 10, question: "What is the difference between FutureBuilder and StreamBuilder in Flutter?", type: "normal" },
  { id: 11, question: "What are plugins and packages in Flutter, and how do they differ?", type: "normal" },
  { id: 12, question: "Explain the use of Navigator and Routes in Flutter.", type: "normal" },
  { id: 13, question: "How does state management work in Flutter?", type: "normal" },
  { id: 14, question: "What are some popular state management solutions in Flutter?", type: "normal" },
  { id: 15, question: "What is the purpose of Dart's async and await keywords?", type: "normal" },
  { id: 16, question: "What is the difference between SizedBox and Container in Flutter?", type: "normal" },
  { id: 17, question: "How is a ListView different from a GridView in Flutter?", type: "normal" },
  { id: 18, question: "What is Flutter’s pubspec.yaml file used for?", type: "normal" },
  { id: 19, question: "How do you implement localization in a Flutter app?", type: "normal" },
  { id: 20, question: "What is the difference between Dart’s `final` and `const` keywords?", type: "normal" },
  { id: 21, question: "What is the difference between a StatefulWidget and a StatelessWidget in Flutter?", type: "normal" },
  { id: 22, question: "How can you manage dependencies in Flutter using pubspec.yaml?", type: "normal" },
  { id: 23, question: "How do you handle navigation and deep linking in Flutter?", type: "normal" },
  { id: 24, question: "What is the role of the `BuildContext` in Flutter, and when should it be used?", type: "normal" },
  { id: 25, question: "Explain the concept of InheritedWidget and how it is used in Flutter.", type: "normal" },
  { id: 26, question: "How can you make an app responsive in Flutter for different screen sizes?", type: "normal" },
  { id: 27, question: "What are the different ways to handle errors in Flutter applications?", type: "normal" },
  { id: 28, question: "What is the difference between `const` and `final` in Dart, and how are they used in Flutter?", type: "normal" },
  { id: 29, question: "How would you handle asynchronous operations in Flutter?", type: "normal" },
  { id: 30, question: "Explain the concept of widgets in Flutter and how they form the building blocks of a UI.", type: "normal" },
  { id: 31, question: "How would you implement offline functionality in a Flutter app?", type: "normal" },
  { id: 32, question: "Explain the role of the `flutter_bloc` package in managing state in Flutter.", type: "normal" },
  { id: 33, question: "How can you integrate Firebase Authentication into a Flutter application?", type: "normal" },
  { id: 34, question: "What is the purpose of the `async` and `await` keywords in Dart, and how do they affect Flutter app performance?", type: "normal" },
  { id: 35, question: "What are Flutter Streams, and how do you use them to manage data flow?", type: "normal" },
  { id: 36, question: "What is the purpose of the `FutureBuilder` widget in Flutter, and when would you use it?", type: "normal" },
  { id: 37, question: "How can you implement a custom scroll view in Flutter?", type: "normal" },
  { id: 38, question: "Describe the difference between a `Row` and `Column` widget in Flutter.", type: "normal" },
  { id: 39, question: "How would you implement a secure login flow in Flutter?", type: "normal" },
  { id: 40, question: "What are some common performance optimization techniques in Flutter?", type: "normal" },
  { id: 41, question: "You are building a news app in Flutter. How would you display a list of articles from a REST API in a scrollable view?", type: "normal" },
  { id: 42, question: "You need to build a Flutter app that displays dynamic content based on user preferences. How would you handle this?", type: "normal" },
  { id: 43, question: "Imagine you need to add authentication and authorization to a Flutter app. How would you approach this?", type: "normal" },
  { id: 44, question: "You are tasked with integrating a third-party payment gateway into a Flutter app. How would you proceed?", type: "normal" },
  { id: 45, question: "How would you implement pagination in a Flutter app that loads data from an API?", type: "normal" },
  { id: 46, question: "You need to implement a feature where users can upload photos from their phone to your app. How would you do this in Flutter?", type: "normal" },
  { id: 47, question: "In a Flutter app, how would you store user data securely, such as passwords or sensitive information?", type: "normal" },
  { id: 48, question: "You are building a shopping app in Flutter. How would you implement a shopping cart and checkout process?", type: "normal" },
  { id: 49, question: "How would you optimize a Flutter app that loads large datasets into memory, such as images or videos?", type: "normal" },
  { id: 50, question: "You need to implement notifications in your Flutter app. What would be the steps to set up push notifications using Firebase Cloud Messaging?", type: "normal" },
  { id: 51, question: "What are the differences between Flutter's `Container` and `Column` widgets?", type: "normal" },
  { id: 52, question: "How would you handle different app themes in Flutter, such as light and dark modes?", type: "normal" },
  { id: 53, question: "What is the purpose of the `setState()` method in Flutter, and how does it work?", type: "normal" },
  { id: 54, question: "How do you implement a tab bar in Flutter and switch between different screens?", type: "normal" },
  { id: 55, question: "What is a `Stream` in Dart, and how is it used to manage real-time data in Flutter?", type: "normal" },
  { id: 56, question: "Explain the role of `InheritedWidget` in Flutter and its practical use cases.", type: "normal" },
  { id: 57, question: "What is the purpose of Flutter's `Text` widget, and how do you customize its appearance?", type: "normal" },
  { id: 58, question: "How does Flutter handle accessibility, and what tools are available to ensure your app is accessible?", type: "normal" },
  { id: 59, question: "What is Flutter’s `GlobalKey` widget, and when should it be used?", type: "normal" },
  { id: 60, question: "How do you handle deep linking in Flutter for navigating to specific parts of the app?", type: "normal" },
  { id: 61, question: "Explain the process of debugging Flutter apps and the available tools for error tracking.", type: "normal" },
  { id: 62, question: "What are Flutter’s `Scaffold` and `AppBar` widgets, and how are they used to structure an app?", type: "normal" },
  { id: 63, question: "What is the purpose of Flutter’s `Provider` package, and how do you use it for state management?", type: "normal" },
  { id: 64, question: "How do you handle user input validation and form submission in Flutter?", type: "normal" },
  { id: 65, question: "What is Flutter's `CustomPainter` widget, and how would you use it to create custom drawings?", type: "normal" },
  { id: 66, question: "How can you implement lazy loading in a Flutter app?", type: "normal" },
  { id: 67, question: "What is the difference between `Stream` and `Future` in Dart, and when would you use each in Flutter?", type: "normal" },
  { id: 68, question: "How would you handle image caching in Flutter?", type: "normal" },
  { id: 69, question: "How can you create a responsive layout for multiple screen sizes in Flutter?", type: "normal" },
  { id: 70, question: "What is Flutter’s `InheritedWidget` and how is it different from a `Provider`?", type: "normal" },
  { id: 71, question: "How do you manage app configuration for different environments in Flutter?", type: "normal" },
  { id: 72, question: "What is the use of `CustomPainter` in Flutter, and how do you implement custom drawing?", type: "normal" },
  { id: 73, question: "How do you handle asynchronous programming in Flutter and Dart?", type: "normal" },
  { id: 74, question: "What is the `flutter doctor` command and how does it help in troubleshooting?", type: "normal" },
  { id: 75, question: "How do you add native Android or iOS code to a Flutter app?", type: "normal" },
  { id: 76, question: "Explain the concept of Flutter plugins and how to use them in an app.", type: "normal" },
  { id: 77, question: "How can you implement background tasks in a Flutter app?", type: "normal" },
  { id: 78, question: "What are the best practices for managing app state in Flutter?", type: "normal" },
  { id: 79, question: "How do you set up and use Firebase in a Flutter app?", type: "normal" },
  { id: 80, question: "Explain the role of `setState()` in Flutter and how it triggers UI updates.", type: "normal" },
  { id: 81, question: "What is a `GlobalKey` in Flutter, and when should it be used?", type: "normal" },
  { id: 82, question: "How do you handle push notifications in a Flutter app?", type: "normal" },
  { id: 83, question: "What is the difference between `Navigator` and `Navigator 2.0` in Flutter?", type: "normal" },
  { id: 84, question: "How would you implement a custom font in a Flutter app?", type: "normal" },
  { id: 85, question: "What is the difference between `Column` and `Row` widgets in Flutter?", type: "normal" },
  { id: 86, question: "How can you make an app responsive using the `LayoutBuilder` in Flutter?", type: "normal" },
  { id: 87, question: "How do you implement gesture detection in Flutter?", type: "normal" },
  { id: 88, question: "What are some common performance optimization techniques in Flutter?", type: "normal" },
  { id: 89, question: "What is the role of `MaterialApp` in a Flutter project?", type: "normal" },
  { id: 90, question: "How would you handle error handling and exceptions in Flutter?", type: "normal" },
  { id: 91, question: "What is the role of `FutureBuilder` in Flutter?", type: "normal" },
  { id: 92, question: "How would you implement a custom widget in Flutter?", type: "normal" },
  { id: 93, question: "What is the difference between `Container` and `SizedBox` in Flutter?", type: "normal" },
  { id: 94, question: "What is the role of `Dart:async` library in Flutter?", type: "normal" },
  { id: 95, question: "How do you add animations to widgets in Flutter?", type: "normal" },
  { id: 96, question: "What is the `MaterialPageRoute` in Flutter, and how is it used?", type: "normal" },
  { id: 97, question: "How do you set up deep linking in a Flutter app?", type: "normal" },
  { id: 98, question: "How do you handle JSON parsing and serialization in Flutter?", type: "normal" },
  { id: 99, question: "What is the role of `CustomScrollView` in Flutter, and how is it different from `ListView`?", type: "normal" },
  { id: 100, question: "What is the purpose of the `flutter_test` package in Flutter?", type: "normal" },
  { id: 101, question: "How do you set up Firebase Cloud Messaging for push notifications in Flutter?", type: "normal" },
  { id: 102, question: "What is the `StreamBuilder` widget in Flutter, and how is it used?", type: "normal" },
  { id: 103, question: "How would you implement a login flow in Flutter with Firebase Authentication?", type: "normal" },
  { id: 104, question: "How do you use the `StreamController` in Dart for stream management?", type: "normal" },
  { id: 105, question: "What is the `ChangeNotifier` class in Flutter, and how do you use it for state management?", type: "normal" },
  { id: 106, question: "What is a `ListView` in Flutter, and how do you implement it?", type: "normal" },
  { id: 107, question: "What is the difference between `mainAxisAlignment` and `crossAxisAlignment`?", type: "normal" },
  { id: 108, question: "How do you use the `TextEditingController` in Flutter?", type: "normal" },
  { id: 109, question: "How do you handle input validation in Flutter forms?", type: "normal" },
  { id: 110, question: "What is the difference between `Future` and `Stream` in Dart?", type: "normal" },
  { id: 111, question: "What is the `TextFormField` widget in Flutter, and how is it different from `TextField`?", type: "normal" },
  { id: 112, question: "How would you handle user authentication in Flutter?", type: "normal" },
  { id: 113, question: "How do you implement a navigation drawer in Flutter?", type: "normal" },
  { id: 114, question: "What is `flutter_local_notifications` package and how can you use it?", type: "normal" },
  { id: 115, question: "How do you add a custom route in Flutter navigation?", type: "normal" },
  { id: 116, question: "How can you use the `PageView` widget to create swipeable pages in Flutter?", type: "normal" },
  { id: 117, question: "How do you manage a shared state between different widgets in Flutter?", type: "normal" },
  { id: 118, question: "How would you implement `app bar` customization in Flutter?", type: "normal" },
  { id: 119, question: "How do you add custom icons and images to a Flutter project?", type: "normal" },
  { id: 120, question: "What are `Streams` in Dart and how do you use them in Flutter?", type: "normal" },
  { id: 121, question: "How do you handle network requests and responses in Flutter?", type: "normal" },
  { id: 122, question: "What are the different types of `Navigator` in Flutter, and when would you use each?", type: "normal" },
  { id: 123, question: "How would you optimize the performance of a Flutter app?", type: "normal" },
  { id: 124, question: "How do you handle exceptions in Flutter?", type: "normal" },
  { id: 125, question: "What is `Provider` in Flutter and how is it used for state management?", type: "normal" },
  { id: 126, question: "How do you create and display custom dialogs in Flutter?", type: "normal" },
  { id: 127, question: "What is the difference between `Scaffold` and `Container` in Flutter?", type: "normal" },
  { id: 128, question: "How do you use `FutureBuilder` to handle asynchronous data in Flutter?", type: "normal" },
  { id: 129, question: "What are `Keys` in Flutter, and when would you use them?", type: "normal" },
  { id: 130, question: "How do you implement dynamic theming in a Flutter app?", type: "normal" },
  { id: 131, question: "What is the role of `flutter_bloc` in Flutter applications?", type: "normal" },
  { id: 132, question: "How would you store data locally in Flutter using `SharedPreferences`?", type: "normal" },
  { id: 133, question: "What is `Bloc` pattern in Flutter, and how do you use it?", type: "normal" },
  { id: 134, question: "How would you implement custom animations in Flutter?", type: "normal" },
  { id: 135, question: "What is the purpose of `flutter pub` and how is it used?", type: "normal" },
  { id: 136, question: "What is `DartDevTools` and how does it help in debugging Flutter apps?", type: "normal" },
  { id: 137, question: "How can you manage asynchronous operations using `async` and `await` in Flutter?", type: "normal" },
  { id: 138, question: "How would you integrate REST APIs in a Flutter app?", type: "normal" },
  { id: 139, question: "What is the `setState()` method, and how does it affect widget rebuilding in Flutter?", type: "normal" },
  { id: 140, question: "How do you implement multi-language support in a Flutter app?", type: "normal" },
  { id: 141, question: "What are `GlobalKeys` in Flutter, and when do you use them?", type: "normal" },
  { id: 142, question: "How do you implement a custom scroll effect in Flutter?", type: "normal" },
  { id: 143, question: "How do you implement complex forms in Flutter?", type: "normal" },
  { id: 144, question: "What is `Flutter Web` and how is it different from mobile Flutter apps?", type: "normal" },
  { id: 145, question: "What are `Dart Streams`, and how do you use them for async programming in Flutter?", type: "normal" },
  { id: 146, question: "What is `flutter_test` and how is it used to test Flutter apps?", type: "normal" },
  { id: 147, question: "How do you use `ListView.builder()` to display large data in Flutter?", type: "normal" },
  { id: 148, question: "How would you implement a circular progress indicator in Flutter?", type: "normal" },
  { id: 149, question: "What is `Image.asset()` used for in Flutter, and how do you load local images?", type: "normal" },
  { id: 150, question: "How do you implement a bottom navigation bar in Flutter?", type: "normal" },

];

export default FLUTTER_QUESTIONS