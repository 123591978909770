const REACTJS_QUESTIONS = [
  // Normal Questions
  { id: 1, question: "What is React.js and why is it used?", type: "normal" },
  { id: 2, question: "What are components in React?", type: "normal" },
  { id: 3, question: "What is the difference between state and props in React?", type: "normal" },
  { id: 4, question: "What is JSX in React?", type: "normal" },
  { id: 5, question: "What are functional components in React?", type: "normal" },
  { id: 6, question: "What are class components in React?", type: "normal" },
  { id: 7, question: "What is React's Virtual DOM?", type: "normal" },
  { id: 8, question: "What is the use of `useState` hook in React?", type: "normal" },
  { id: 9, question: "What is the use of `useEffect` hook in React?", type: "normal" },
  { id: 10, question: "What are hooks in React?", type: "normal" },
  { id: 11, question: "What is the purpose of `React Router`?", type: "normal" },
  { id: 12, question: "What are controlled components in React?", type: "normal" },
  { id: 13, question: "What are uncontrolled components in React?", type: "normal" },
  { id: 14, question: "What is the purpose of `React.Fragment`?", type: "normal" },
  { id: 15, question: "What is Context API in React?", type: "normal" },
  { id: 16, question: "What is Redux, and how does it work with React?", type: "normal" },
  { id: 17, question: "What are Pure Components in React?", type: "normal" },
  { id: 18, question: "What is the difference between `componentDidMount` and `useEffect`?", type: "normal" },
  { id: 19, question: "What is React.memo?", type: "normal" },
  { id: 20, question: "What is the purpose of the `useContext` hook in React?", type: "normal" },

  // More normal questions
  { id: 21, question: "What is the difference between state and props in React?", type: "normal" },
  { id: 22, question: "How does React handle events?", type: "normal" },
  { id: 23, question: "What is the difference between `React.Component` and `React.PureComponent`?", type: "normal" },
  { id: 24, question: "What is the use of `useCallback` hook?", type: "normal" },
  { id: 25, question: "How would you manage forms in React?", type: "normal" },
  { id: 26, question: "What is the purpose of `shouldComponentUpdate`?", type: "normal" },
  { id: 27, question: "Explain the concept of higher-order components (HOC).", type: "normal" },
  { id: 28, question: "What is the significance of keys in React lists?", type: "normal" },
  { id: 29, question: "What is the importance of the `useEffect` hook?", type: "normal" },
  { id: 30, question: "What is `React.StrictMode`?", type: "normal" },

  // Coding Questions
  { id: 31, question: "Write a React component that renders a button and tracks how many times it is clicked.", type: "coding" },
  { id: 32, question: "Write a React component that fetches data from an API and displays it.", type: "coding" },
  { id: 33, question: "Write a React component using functional components and hooks to manage the state.", type: "coding" },
  { id: 34, question: "Write a React component that toggles between two states when a button is clicked.", type: "coding" },
  { id: 35, question: "Write a React component that takes user input and displays it on the screen.", type: "coding" },
  { id: 36, question: "Write a React component that uses `useEffect` to perform an operation when the component mounts.", type: "coding" },
  { id: 37, question: "Write a React component that implements a simple form with validation.", type: "coding" },
  { id: 38, question: "Write a React component that implements a counter with increment and decrement buttons.", type: "coding" },
  { id: 39, question: "Write a React component using the Context API to manage global state.", type: "coding" },
  { id: 40, question: "Write a React component that implements conditional rendering based on a user’s role.", type: "coding" },

  // More coding questions
  { id: 41, question: "Write a React component that fetches data and displays it in a list using `map`.", type: "coding" },
  { id: 42, question: "Write a React component that implements a simple to-do list.", type: "coding" },
  { id: 43, question: "Write a React component that uses `React.memo` to optimize performance.", type: "coding" },
  { id: 44, question: "Write a React component that uses `useReducer` to manage complex state.", type: "coding" },
  { id: 45, question: "Write a React component that implements routing using `react-router-dom`.", type: "coding" },
  { id: 46, question: "Write a React component that uses `useRef` to focus on an input element.", type: "coding" },
  { id: 47, question: "Write a React component that implements a modal window.", type: "coding" },
  { id: 48, question: "Write a React component that implements lazy loading for images.", type: "coding" },
  { id: 49, question: "Write a React component that implements pagination for a list of items.", type: "coding" },
  { id: 50, question: "Write a React component that handles form submission and displays a success message.", type: "coding" },

  // Advanced React Topics
  { id: 51, question: "What is React Fiber?", type: "normal" },
  { id: 52, question: "How would you optimize the performance of a React application?", type: "normal" },
  { id: 53, question: "What are React Suspense and Concurrent Mode?", type: "normal" },
  { id: 54, question: "How does React's reconciliation algorithm work?", type: "normal" },
  { id: 55, question: "What is the use of `useLayoutEffect`?", type: "normal" },
  { id: 56, question: "What are React hooks? How do they work?", type: "normal" },
  { id: 57, question: "What is the difference between `useEffect` and `useLayoutEffect`?", type: "normal" },
  { id: 58, question: "What are error boundaries in React?", type: "normal" },
  { id: 59, question: "Explain the concept of Context API and when would you use it.", type: "normal" },
  { id: 60, question: "What are the benefits of using Redux over React's Context API?", type: "normal" },

  // Performance and Optimization
  { id: 61, question: "How would you optimize a React app that is running slowly?", type: "normal" },
  { id: 62, question: "Explain React's memoization techniques and how they improve performance.", type: "normal" },
  { id: 63, question: "How do you implement code-splitting in React?", type: "normal" },
  { id: 64, question: "How can you implement lazy loading in React?", type: "normal" },
  { id: 65, question: "What are some best practices for managing side effects in React?", type: "normal" },
  { id: 66, question: "How can you avoid unnecessary re-renders in React?", type: "normal" },

  // Testing
  { id: 67, question: "How would you test a React component?", type: "normal" },
  { id: 68, question: "What are the different testing utilities available for React?", type: "normal" },
  { id: 69, question: "What is the purpose of `jest` and `enzyme` for React testing?", type: "normal" },
  { id: 70, question: "How do you test a component with asynchronous operations?", type: "normal" },
  { id: 71, question: "What is snapshot testing in React?", type: "normal" },

  // Redux
  { id: 72, question: "What is Redux and how does it work with React?", type: "normal" },
  { id: 73, question: "Explain the core principles of Redux.", type: "normal" },
  { id: 74, question: "What is the Redux store?", type: "normal" },
  { id: 75, question: "What are actions and reducers in Redux?", type: "normal" },
  { id: 76, question: "What is the purpose of `connect` in Redux?", type: "normal" },
  { id: 77, question: "How does the `dispatch` function work in Redux?", type: "normal" },
  { id: 78, question: "What is the `Provider` component in Redux?", type: "normal" },
  { id: 79, question: "What is the purpose of `useSelector` and `useDispatch` hooks in Redux?", type: "normal" },

  // Miscellaneous
  { id: 80, question: "What is the difference between `React.createElement` and JSX?", type: "normal" },
  { id: 81, question: "Explain how to create a reusable component in React.", type: "normal" },
  { id: 82, question: "What are some advantages of using React over other JavaScript frameworks?", type: "normal" },
  { id: 83, question: "What is server-side rendering (SSR) in React?", type: "normal" },
  { id: 84, question: "What are controlled and uncontrolled components in React?", type: "normal" },

  // More advanced topics
  { id: 85, question: "What are the differences between `useMemo` and `useCallback`?", type: "normal" },
  { id: 86, question: "What are React Portals?", type: "normal" },
  { id: 87, question: "What is the purpose of `React.StrictMode`?", type: "normal" },
  { id: 88, question: "How do you handle errors in React?", type: "normal" },
  { id: 89, question: "What is the role of `useRef` hook in React?", type: "normal" },
  { id: 90, question: "Explain the use of `dangerouslySetInnerHTML` in React.", type: "normal" },
  { id: 91, question: "What is React Native and how does it differ from React?", type: "normal" },
  { id: 92, question: "What are the advantages of React over Angular?", type: "normal" },
  { id: 93, question: "What is a render prop?", type: "normal" },
  { id: 94, question: "Explain the concept of a higher-order component (HOC).", type: "normal" },
  { id: 95, question: "What is the purpose of `setState` in React?", type: "normal" },
  { id: 96, question: "What is the significance of `React.Fragment`?", type: "normal" },
  { id: 97, question: "How do you integrate React with APIs?", type: "normal" },
  { id: 98, question: "How do you implement authentication in a React application?", type: "normal" },
  { id: 99, question: "What is the significance of `useEffect` hook for data fetching?", type: "normal" },
  { id: 100, question: "How do you create and handle error boundaries in React?", type: "normal" },
  // Basics and React Setup
  { id: 101, question: "What are the benefits of using React.js?", type: "normal" },
  { id: 102, question: "How does React differ from other JavaScript frameworks?", type: "normal" },
  { id: 103, question: "What are functional components and class components in React?", type: "normal" },
  { id: 104, question: "How do you handle events in React?", type: "normal" },
  { id: 105, question: "How do you pass data between components in React?", type: "normal" },
  { id: 106, question: "What is the use of `key` prop in React?", type: "normal" },
  { id: 107, question: "How do you handle form data in React?", type: "normal" },
  { id: 108, question: "What is the role of `componentWillMount` in React?", type: "normal" },
  { id: 109, question: "What is the difference between `componentWillUnmount` and `componentWillUpdate`?", type: "normal" },
  { id: 110, question: "What are the lifecycle methods of a React component?", type: "normal" },

  // Hooks and Functional Components
  { id: 111, question: "What are the different React hooks and their uses?", type: "normal" },
  { id: 112, question: "What is `useState` hook in React?", type: "normal" },
  { id: 113, question: "What is `useEffect` hook in React?", type: "normal" },
  { id: 114, question: "What is `useReducer` hook in React?", type: "normal" },
  { id: 115, question: "What is `useContext` hook in React?", type: "normal" },
  { id: 116, question: "What is the difference between `useEffect` and `useLayoutEffect`?", type: "normal" },
  { id: 117, question: "What is the `useRef` hook used for?", type: "normal" },
  { id: 118, question: "How do you fetch data using `useEffect`?", type: "normal" },
  { id: 119, question: "How do you create a custom hook in React?", type: "normal" },
  { id: 120, question: "How do you use `useCallback` and `useMemo` hooks in React?", type: "normal" },

  // Components and Props
  { id: 121, question: "What are components in React?", type: "normal" },
  { id: 122, question: "What is the difference between state and props in React?", type: "normal" },
  { id: 123, question: "How do you make a component re-render in React?", type: "normal" },
  { id: 124, question: "What are pure components in React?", type: "normal" },
  { id: 125, question: "What is the `defaultProps` property in React?", type: "normal" },
  { id: 126, question: "What is prop drilling and how do you avoid it?", type: "normal" },
  { id: 127, question: "How do you pass data between sibling components?", type: "normal" },
  { id: 128, question: "What is `React.Fragment` used for?", type: "normal" },
  { id: 129, question: "What are `children` in React?", type: "normal" },
  { id: 130, question: "What is the `context` API in React?", type: "normal" },

  // Routing and Navigation
  { id: 131, question: "What is React Router and how does it work?", type: "normal" },
  { id: 132, question: "How do you implement routing in React?", type: "normal" },
  { id: 133, question: "What is the difference between `Switch` and `Route` in React Router?", type: "normal" },
  { id: 134, question: "What are `NavLink` and `Link` in React Router?", type: "normal" },
  { id: 135, question: "How do you pass props to a route in React Router?", type: "normal" },
  { id: 136, question: "What are `useHistory` and `useLocation` hooks in React Router?", type: "normal" },
  { id: 137, question: "What is lazy loading in React Router?", type: "normal" },
  { id: 138, question: "What is the purpose of `exact` in React Router?", type: "normal" },

  // State Management and Redux
  { id: 139, question: "What is Redux and how does it work with React?", type: "normal" },
  { id: 140, question: "What are actions, reducers, and stores in Redux?", type: "normal" },
  { id: 141, question: "What is the role of `dispatch` in Redux?", type: "normal" },
  { id: 142, question: "How do you connect Redux to a React component?", type: "normal" },
  { id: 143, question: "What are the differences between React's Context API and Redux?", type: "normal" },
  { id: 144, question: "How do you handle asynchronous actions in Redux?", type: "normal" },
  { id: 145, question: "What is Redux Thunk?", type: "normal" },
  { id: 146, question: "What is the purpose of `useDispatch` and `useSelector` hooks in Redux?", type: "normal" },
  { id: 147, question: "What is Redux DevTools?", type: "normal" },
  { id: 148, question: "What are middlewares in Redux?", type: "normal" },

  // Performance Optimization
  { id: 149, question: "How do you optimize a React app for better performance?", type: "normal" },
  { id: 150, question: "How does `React.memo()` optimize the performance of functional components?", type: "normal" },
  { id: 151, question: "What is the `shouldComponentUpdate` lifecycle method used for?", type: "normal" },
  { id: 152, question: "What are the best practices for optimizing re-renders in React?", type: "normal" },
  { id: 153, question: "How do you handle infinite scrolling in React?", type: "normal" },
  { id: 154, question: "How do you implement code splitting in React?", type: "normal" },
  { id: 155, question: "What is the `useMemo` hook used for?", type: "normal" },
  { id: 156, question: "What is `lazy loading` in React and how do you implement it?", type: "normal" },
  { id: 157, question: "How does React's Virtual DOM improve performance?", type: "normal" },

  // Testing in React
  { id: 158, question: "How do you test a React component?", type: "normal" },
  { id: 159, question: "What is Jest and how does it work with React?", type: "normal" },
  { id: 160, question: "What is Enzyme and how does it help in React testing?", type: "normal" },
  { id: 161, question: "What is snapshot testing in React?", type: "normal" },
  { id: 162, question: "What is shallow rendering in React testing?", type: "normal" },
  { id: 163, question: "How do you test asynchronous code in React?", type: "normal" },
  { id: 164, question: "How do you mock API calls in React testing?", type: "normal" },
  { id: 165, question: "What are the different types of testing for React applications?", type: "normal" },

  // Miscellaneous
  { id: 166, question: "What is the difference between `React.createElement()` and JSX?", type: "normal" },
  { id: 167, question: "What is `dangerouslySetInnerHTML` used for in React?", type: "normal" },
  { id: 168, question: "What is React’s reconciliation process?", type: "normal" },
  { id: 169, question: "What is React Native?", type: "normal" },
  { id: 170, question: "What are some of the advantages of using React Native?", type: "normal" },
  { id: 171, question: "What is a higher-order component in React?", type: "normal" },
  { id: 172, question: "What are some ways to prevent unnecessary re-renders in React?", type: "normal" },
  { id: 173, question: "How do you handle error boundaries in React?", type: "normal" },
  { id: 174, question: "What are portals in React?", type: "normal" },
  { id: 175, question: "What are the differences between React and Angular?", type: "normal" },

  // Advanced Concepts
  { id: 176, question: "What is the React Context API and how do you use it?", type: "normal" },
  { id: 177, question: "What are render props in React?", type: "normal" },
  { id: 178, question: "What is the purpose of `React.StrictMode`?", type: "normal" },
  { id: 179, question: "What is the importance of `React.lazy()` in React?", type: "normal" },
  { id: 180, question: "What is the purpose of `useEffect` hook for side effects?", type: "normal" },
  { id: 181, question: "What is the purpose of `useLayoutEffect` hook?", type: "normal" },
  { id: 182, question: "How do you manage side effects in React?", type: "normal" },
  { id: 183, question: "What are controlled components in React?", type: "normal" },
  { id: 184, question: "What are uncontrolled components in React?", type: "normal" },
  { id: 185, question: "How does React handle forms?", type: "normal" },

  // Debugging and Error Handling
  { id: 186, question: "What are the common ways to debug React applications?", type: "normal" },
  { id: 187, question: "What is the `componentDidCatch` method in React?", type: "normal" },
  { id: 188, question: "How do you handle errors in React?", type: "normal" },
  { id: 189, question: "What is `Error Boundaries` in React?", type: "normal" },
  { id: 190, question: "How do you handle uncaught errors in React?", type: "normal" },
  
  // Performance and Optimization
  { id: 191, question: "What is the purpose of `React.memo`?", type: "normal" },
  { id: 192, question: "How do you optimize component re-renders in React?", type: "normal" },
  { id: 193, question: "How do you implement server-side rendering (SSR) in React?", type: "normal" },
  { id: 194, question: "What is `useMemo` and how is it used?", type: "normal" },
  { id: 195, question: "What are the benefits of using TypeScript with React?", type: "normal" },

  // Basics and React Setup (continued)
  { id: 196, question: "What is JSX and how does it work in React?", type: "normal" },
  { id: 197, question: "What are the advantages of JSX?", type: "normal" },
  { id: 198, question: "How do you embed expressions in JSX?", type: "normal" },
  { id: 199, question: "What is the difference between `React.createElement()` and JSX?", type: "normal" },
  { id: 200, question: "How does React handle events differently from traditional DOM events?", type: "normal" },
  { id: 201, question: "What are the differences between `useState` and `useReducer` hooks?", type: "normal" },
  { id: 202, question: "What is the virtual DOM, and why does React use it?", type: "normal" },
  { id: 203, question: "How does React update the UI when state changes?", type: "normal" },
  { id: 204, question: "What is the role of `ReactDOM.render()`?", type: "normal" },
  { id: 205, question: "How does React handle browser events?", type: "normal" },

  // Functional and Class Components (continued)
  { id: 206, question: "What is the difference between stateless and stateful components?", type: "normal" },
  { id: 207, question: "What is `PureComponent` and how is it different from a regular component?", type: "normal" },
  { id: 208, question: "How do you create a controlled input in React?", type: "normal" },
  { id: 209, question: "What is a functional component with hooks?", type: "normal" },
  { id: 210, question: "What is `componentDidMount` lifecycle method?", type: "normal" },
  { id: 211, question: "What is `componentDidUpdate` lifecycle method?", type: "normal" },
  { id: 212, question: "What is the `render` method in a React component?", type: "normal" },
  { id: 213, question: "What is `shouldComponentUpdate` used for?", type: "normal" },
  { id: 214, question: "What is the `getDerivedStateFromProps` lifecycle method?", type: "normal" },
  { id: 215, question: "What is the purpose of `getSnapshotBeforeUpdate`?", type: "normal" },

  // Advanced Features and Techniques
  { id: 216, question: "What are higher-order components (HOCs) in React?", type: "normal" },
  { id: 217, question: "What are render props in React?", type: "normal" },
  { id: 218, question: "What is `React.lazy` and how is it used?", type: "normal" },
  { id: 219, question: "What is the `Suspense` component in React?", type: "normal" },
  { id: 220, question: "How do you optimize performance using `React.memo`?", type: "normal" },
  { id: 221, question: "What is the purpose of `React.Fragment`?", type: "normal" },
  { id: 222, question: "How do you create dynamic routes in React?", type: "normal" },
  { id: 223, question: "What is `useContext` hook and how does it work?", type: "normal" },
  { id: 224, question: "How do you use `useRef` to access DOM elements?", type: "normal" },
  { id: 225, question: "What is the difference between `useEffect` and `useLayoutEffect`?", type: "normal" },

  // Performance and Optimization (continued)
  { id: 226, question: "What is the purpose of lazy loading in React?", type: "normal" },
  { id: 227, question: "How do you reduce the size of a React application?", type: "normal" },
  { id: 228, question: "How does React handle reconciliation?", type: "normal" },
  { id: 229, question: "What is `React.StrictMode`?", type: "normal" },
  { id: 230, question: "What is code splitting in React?", type: "normal" },
  { id: 231, question: "How do you use Webpack with React?", type: "normal" },
  { id: 232, question: "How do you optimize the build process in React?", type: "normal" },
  { id: 233, question: "What are the best practices for optimizing re-renders in React?", type: "normal" },
  { id: 234, question: "What is `useMemo` and when should it be used?", type: "normal" },
  { id: 235, question: "What is the importance of keys in React lists?", type: "normal" },

  // Testing and Debugging React
  { id: 236, question: "How do you test React components?", type: "normal" },
  { id: 237, question: "What is Jest and how do you use it for React testing?", type: "normal" },
  { id: 238, question: "What is Enzyme and how does it relate to React?", type: "normal" },
  { id: 239, question: "How do you perform snapshot testing in React?", type: "normal" },
  { id: 240, question: "How do you handle async code in React testing?", type: "normal" },
  { id: 241, question: "What are the best practices for debugging React applications?", type: "normal" },
  { id: 242, question: "How do you handle global state in React testing?", type: "normal" },
  { id: 243, question: "What is shallow rendering in React?", type: "normal" },
  { id: 244, question: "What are the testing utilities for React?", type: "normal" },
  { id: 245, question: "How do you mock functions in React testing?", type: "normal" },

  // Advanced Topics and Best Practices
  { id: 246, question: "What is the purpose of error boundaries in React?", type: "normal" },
  { id: 247, question: "What is the `componentWillUnmount` lifecycle method?", type: "normal" },
  { id: 248, question: "What is server-side rendering (SSR) in React?", type: "normal" },
  { id: 249, question: "How do you use Redux for global state management in React?", type: "normal" },
  { id: 250, question: "What are the key differences between React and Angular?", type: "normal" }

];

export default REACTJS_QUESTIONS